export default {
  userDetails: state => state.userDetails,
  properties: state => state.properties,
  charmSubscribedProperties:  state => state.charmSubscribedProperties,
  charmSubscribedAndFilteredProperties: state => state.charmSubscribedAndFilteredProperties,
  filteredProperties: state => state.filteredProperties,
  charmSubscribedNotSelectedProperties: state => state.charmSubscribedNotSelectedProperties,
  hasCharmSubscribedPropertySelected: state => {
    if (state.charmSubscribedProperties.length && state.filteredProperties.length) {
     return state.charmSubscribedProperties.some((a) => state.filteredProperties.find((b) => a.property_id === b));
    }
    return false;
  },
  isCharmSubscribedProperty: state => (id) => {
    if (state.charmSubscribedProperties.length && !!id) {
      return state.charmSubscribedProperties.some(a => a.property_id === id);
    }
    return false;
  },
  isPropertySelected: state => (id) => {
    if (state.filteredProperties.length && !!id) {
      return state.filteredProperties.some(a => a === id);
    }
    return false;
  },
};
