import Vue from 'vue'
function serialize(obj){
	return Object.keys(obj).map(function(k){
		return encodeURIComponent(k) + '=' + encodeURIComponent(obj[k])
	}).join('&');
}

function redirectToLogin(response, context){
    // This condition is ir-relevant "!context.errorHas('Login')".
    // For redirect we can only check response status 401 and then can redirect to login page
    
    if (response.status == 401) {
      if(context) context.errorSet('Login', response.body.msg);
      return context.$router.push({ path: '/login', query: { redirect: context.$route.path }});
    }
}

export default {

	postFile(context, url,  data, files, contextField){
    context = context.$options ? context: null;
		contextField = contextField || context.$options.name;
		context.startLoading(contextField);
		context.errorClear(contextField);
		var formData = new FormData();

		for (var i = 0; i < files.length; i++) {
			formData.append('file', files[i]);
		}


		for(var key in data){
			if (!data.hasOwnProperty(key)) continue;
			formData.append(key, data[key]);
		}


		return context.$http.post(url, formData, {
			headers: {
				'Content-Type': 'multipart/form-data'
			}
		}).then(response => {
			context.stopLoading(contextField);
			if(response.body.status === true || response.body.status == 200) {
				if (response.body.msg){
					context.successSet(contextField, response.body.msg);
				}
				return response.body.data;
			}

			throw new Error(response.body.msg);

		}, response => {

			context.stopLoading(contextField);
			if(response && response.status === 401){
        try {
          redirectToLogin(response, context);
        } catch(err){
          console.log( err)
        }
      }
			
      if (response.body.msg) throw response.body.msg;

		}).catch((err) => {
      var msg = err ? err.toString() : "An error occurred.";
      if(context){

        context.stopLoading(contextField);
        context.errorSet(contextField, msg);
      }
      return Promise.reject(err);
		});

	},

	post(context, url,  data, contextField = '', formatError = true){
		context = context && context.$options ? context : null;

		if(context){
			contextField = contextField || context.$options.name;
			context.startLoading(contextField);
			context.errorClear(contextField);
		}
		return Vue.http.post(url, data).then(response => {
			if(context) context.stopLoading(contextField);

			if(response.body.status === true || response.body.status === 200) {
				if (response.body.msg){
					if(context) context.successSet(contextField, response.body.msg);
				}
				return response.body.data;
			}
    
      throw response.body.msg;
			// try {
      //   //redirectToLogin(response, context);
      //
      // } catch(err){
      //   console.log(err)
      // }

		}, response => {

      if(context) context.stopLoading(contextField);
    
      if(response && response.status === 401){
        try {
          redirectToLogin(response, context);
        } catch(err){
          console.log( err)
        }
      }
      
      throw formatError ? response.body.msg : response;

		}).catch((err) => {
      
			var msg = err ? err.toString() : "An error occurred.";
			if(context){
				context.stopLoading(contextField);
				context.errorSet(contextField, msg);
      }
			return Promise.reject(err);
		});
	},

  put(context, url,  data, contextField, formatError = true){
    context = context && context.$options ? context : null;
    if(context){
      contextField = contextField || context.$options.name;
      context.startLoading(contextField);
      context.errorClear(contextField);
    }
		return Vue.http.put(url, data).then(response => {
      if(context) context.stopLoading(contextField);
			if(response.body.status === true || response.body.status === 200) {
				if (response.body.msg){
          if(context) context.successSet(contextField, response.body.msg);
				}
				return response.body.data;
			}

			throw new Error(response.body.msg);
		}, response => {

      if(context) context.stopLoading(contextField);
      if(response && response.status === 401){
        try {
          redirectToLogin(response, context);
        } catch(err){
          console.log( err)
        }
      }

			throw formatError ? response.body.msg : response;
			
		}).catch((err) => {
      var msg = err ? err.toString() : "An error occurred.";
      if(context){
        context.stopLoading(contextField);
        context.errorSet(contextField, err);
      }
			return Promise.reject(err);
		})
	},

  patch(context, url,  data, contextField){
    context = context && context.$options ? context : null;
    if(context){
      contextField = contextField || context.$options.name;
      context.startLoading(contextField);
      context.errorClear(contextField);
    }
		return Vue.http.patch(url, data)
    .then(response => {
      if(context) context.stopLoading(contextField);
			if(response.body.status === true || response.body.status === 200) {
				if (response.body.msg){
          if(context) context.successSet(contextField, response.body.msg);
				}
				return response.body.data;
			}
			throw new Error(response.body.msg);
		}, response => {
      if(context) context.stopLoading(contextField);

			if(response && response.status === 401){
        try {
          redirectToLogin(response, context);
        } catch(err){
          console.log( err)
        }
      }

			if (response.body.msg) throw response.body.msg;
			
		}).catch((err) => {
      var msg = err ? err.toString() : "An error occurred.";
      if(context){
        context.stopLoading(contextField);
        context.errorSet(contextField, msg);
      }
			return Promise.reject(err);
		})
	},

  get(context, url, data, contextField){
    if(!url) return;

    context = context && context.$options ? context : null;
    if(data) url += '?' + serialize(data);
    if(context){
      contextField = contextField || context.$options.name;
      context.startLoading(contextField);
      context.errorClear(contextField);
    }

    return Vue.http.get(url).then(response => {

        if(context) context.stopLoading(contextField);
        if(response.body.status === true || response.body.status == 200) {
          if (response.body.msg){
            if(context) context.successSet(contextField, response.body.msg);
          }
          return response.body.data;
        }

        redirectToLogin(response, context);
        // try {
        // } catch(err){
        //   console.log( err)
        // }
        throw response.body.msg;

      }, response => {
        if(context) context.stopLoading(contextField);

        // if(response && response.body){
        //   try {
        redirectToLogin(response, context);
        //   } catch(err){
        //     console.log(err)
        //   }
        // }

        if (response.body.msg) throw response.body.msg;
      }).catch((err) => {
        
        var msg = err ? err.toString() : "An error occurred.";
        if(context){
            context.stopLoading(contextField);
            context.errorSet(contextField, msg);
          }
          return Promise.reject(err);
        })
	},

  delete(context, url, id, contextField){

    context = context && context.$options ? context : null;

    if(context){
      contextField = contextField || context.$options.name;
      context.startLoading(contextField);
      context.errorClear(contextField);
    }

		if(id) url += id;

		return Vue.http.delete(url).then(response => {
      if(context) context.stopLoading(contextField);
      if(response.body.status === true || response.body.status == 200) {
        if (response.body.msg){
          if(context) context.successSet(contextField, response.body.msg);
        }
        return response.body.data;
      }

			throw new Error(response.body.msg);

		}, response => {

      if(context) context.stopLoading(contextField);

      if(response && response.status === 401){
        try {
          redirectToLogin(response, context);
        } catch(err){
          console.log( err)
        }
      }
      
      if (response.body.msg) throw response.body.msg;
      
		}).catch((err) => {
      var msg = err ? err.toString() : "An error occurred.";
      if(context){
        context.stopLoading(contextField);
        context.errorSet(contextField, msg);
      }
      return Promise.reject(err);
		})
	}
}
