import Vue from 'vue'
import moment from 'moment';
import numTowords from 'num-words';

import { parsePhoneNumber } from 'libphonenumber-js';
import { parseCountry } from '../utils/common'
import PLURALS from '@/constants/plurals.js';

Vue.filter('addTimeOffset', function (inputTime, off_set, format = 'MM/DD/YYYY') {
	const formattedOffset = (off_set.startsWith('-') || off_set.startsWith('+')) ? off_set : `+${off_set}`;
	return moment(inputTime).isValid() ? moment.utc(inputTime).utcOffset(formattedOffset).format(format) : inputTime;
});

Vue.filter('formatDate', function(value, format = 'MM/DD/YYYY'){
	if (!value || moment(value).format(format) == 'Invalid date') return '';
	return moment(value).format(format);
});

Vue.filter('formatDateServices', function(value){
	if (!value || moment(value).format('MM/DD/YYYY') == 'Invalid date') return '';
	return moment(value).format('MMM DD, YYYY');
});

Vue.filter('formatDateDocuments', function(value){
	if (!value || moment(value).format('MM/DD/YYYY') == 'Invalid date') return '';
	return moment(value).subtract(8, 'hours').format('MMM DD, YYYY [@] h:mma');
});

Vue.filter('formatLocalFromNow', function(value){
	if (!value) return '';
	return moment.utc(value).local().fromNow();
});

Vue.filter('formatSmallDate', function(value){
	if (!value) return '';
	return moment(value).format('M/D/YY');
});

Vue.filter('formatLocalDateTimeDays', function(value){
	if (!value) return '';

	if (moment.utc(value).local().isSame(new Date(), "day")) {
		//check if date is today
		return `Today, ${moment.utc(value).local().format('h:mma')}`

	} else if (moment.utc(value).local().isSame(moment().utc().local().subtract(1, 'day').startOf('day'), 'day')) {
		//Check if Date was Yesterday
		return `Yesterday, ${moment.utc(value).local().format('h:mma')}`

	} else if (moment.utc(value).local().isAfter(moment().utc().local().subtract(7, 'day').startOf('day'))) {
		//Check if Date within Week
		return moment.utc(value).local().format('dddd, h:mma');
	}
	return moment.utc(value).local().format('MM/DD/YYYY [at] h:mma');
});

Vue.filter('formatDateTimeNotifications', function(value){
	if (!value) return '';
	if (moment.utc(value).local().isSame(new Date(), "day")) {
		//check if date is today
		return `Today, ${moment.utc(value).local().format('h:mma')}`;
	} else if (moment.utc(value).local().isSame(moment().utc().local().subtract(1, 'day').startOf('day'), 'day')) {
		//Check if Date was Yesterday
		return `Yesterday, ${moment.utc(value).local().format('h:mma')}`;
	} else if (moment.utc(value).local().isAfter(moment().utc().local().subtract(7, 'day').startOf('day'))) {
		//Check if Date within Week
		return moment.utc(value).local().format('dddd, h:mma');
	}
	return moment.utc(value).local().format('MMM DD, YYYY h:mma');
});

Vue.filter('formatLocalDateTime', function(value){
	if (!value) return '';
	return moment.utc(value).local().format('MM/DD/YYYY [at] h:mm a');
});

Vue.filter("formatTime12Hour", function (value) {
  if (!value) return "";

  return moment(value).format("hh:mm a");
});

Vue.filter('formatLocalDateTimeServices', function(value){
	if (!value) return '';
	return moment.utc(value).local().format('MMM DD, YYYY [@] h:mma');
});

Vue.filter('formatDateTime', function(value){
	if (!value) return '';
	return moment.utc(value).format('MM/DD/YYYY [at] h:mm a');
});


Vue.filter('formatLocalTime', function(value){
	if (!value) return '';
	return moment.utc(value).local().format('h:mm a');
});

Vue.filter('formatLocalDate', function(value){
	if (!value) return '';
	return moment(value).local().format('MM/DD/YYYY');
});

Vue.filter('formatLocalShortDate', function(value){
	if (!value) return '';
	return moment(value).local().format('MMM DD, YYYY');
});

Vue.filter('formatFileSize', function(value, b=2){
	if (!value) return '';
	const c=0>b?0:b,d=Math.floor(Math.log(value)/Math.log(1024));
	return parseFloat((value/Math.pow(1024,d)).toFixed(c))+" "+["Bytes","KB","MB","GB","TB","PB","EB","ZB","YB"][d];
});

Vue.filter('formatPhone', function(value){
	if (!value) return '';
  try {
    var parsedPhoneNumber = parsePhoneNumber('+' + value);


    if(parsedPhoneNumber && parsedPhoneNumber.isValid()){
      if(parsedPhoneNumber.country === 'US'){
        return '+1 ' + parsedPhoneNumber.formatNational();
      } else {
        return parsedPhoneNumber.formatInternational();
      }
    } else {
      value = value.toString();

      var numbers = value.replace(/\D/g, ''), char = {0: '(', 3: ') ', 6: '-'};
      value = '';
      for (var i = 0; i < numbers.length; i++) {
        value += (char[i] || '') + numbers[i];
      }
    }
  } catch(err){
    console.log(err)
  }
  return value;


});
Vue.filter('formatPhoneFacility', function(value){
	if (!value) return '';
	value = value.toString();

	var numbers = value.replace(/\D/g, ''),  char = {0:'(',3:') ',6:'-'};
	value = '';
	for (var i = 0; i < numbers.length; i++) {
    value += (char[i]||'') + numbers[i];
	}
	return value;

});

Vue.filter('hbFormatPhone', function(value){
	if (!value) return '';
	value = value.toString();

	var numbers = value.replace(/\D/g, ''),  char = {0:'(',3:') ',6:'-'};
	value = '';
	for (var i = 0; i < numbers.length; i++) {
    value += (char[i]||'') + numbers[i];
	}
	return value;

});

Vue.filter('dayEnding', function(i){
  var v;
  var s= ["th","st","nd","rd"];
  v=i%100;
  var label = i + (s[(v-20)%10]||s[v]||s[0]);
  return label;
});




Vue.filter('capitalize', function(value){
	if (!value) return '';
	value = value.toString();
	return value.charAt(0).toUpperCase() + value.slice(1);
});



Vue.filter('formatSSN', function(value){
	if (!value) return '';
	value = value.toString();
	return value.substr(0, 3) + '-' + value.substr(3, 2) + '-' + value.substr(5);
});

Vue.filter('formatMoney', function(value){
	if (typeof value == 'undefined' ||  value === false || value === null  ) return '';
	if(value < 0){
		return "($" + Math.abs(Math.round(value * 1e2) / 1e2).toFixed(2) + ')';
	}
	return "$" + (Math.round(value * 1e2) / 1e2).toFixed(2);
});

Vue.filter('formatNumber', function(value){

	if (typeof value == 'undefined' ||  value === false || value === null  ) return '';
  return value;

});


Vue.filter('formatMinutes', function(value){
	if (value === false || value === null  ) return '';
	return  parseInt(value) > 9 ? "" + parseInt(value): "0" + parseInt(value);
});


Vue.filter('nl2br', function(str){
	if (typeof str === 'undefined' || str === null) {
		return '';
	}
	var breakTag = '<br />';
	return (str + '').replace(/([^>\r\n]?)(\r\n|\n\r|\r|\n)/g, '$1' + breakTag + '$2');
});

Vue.filter('slugify', function(text){
	return text.toString().toLowerCase()
		.replace(/\s+/g, '-')           // Replace spaces with -
		.replace(/[^\w-]+/g, '')       // Remove all non-word chars
		.replace(/--+/g, '-')         // Replace multiple - with single -
		.replace(/^-+/, '')             // Trim - from start of text
		.replace(/-+$/, '');            // Trim - from end of text
});

Vue.filter('formatMoneyWithComma', function(value,digits){

	if (typeof value == 'undefined' ||   isNaN(value) || value === false || value === null  ) {
    value = 0;
  }

	if (typeof digits == 'undefined') {
		digits = 2
	}
	return value.toLocaleString(undefined, {minimumFractionDigits: digits, maximumFractionDigits: digits})
});

Vue.filter('formatMoneyAndAddComma', function(value){

	if (typeof value == 'undefined' ||  value === false || value === null  ) return '';

	let result = Math.abs(Math.round(value * 1e2) / 1e2).toFixed(2);
	result = result.toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2});
	console.log("result", result);
	return (value < 0 ? "(" : "") + "$" + result + (value < 0 ? ")" : "");
});

Vue.filter('formatPercentage', function(value){
	if (typeof value == 'undefined' ||  value === false || value === null  ) return '0.0%';
	let result = Math.round(value * 1e1) / 1e1;
	return result.toLocaleString(undefined, {minimumFractionDigits: 1, maximumFractionDigits: 1}) + '%'
});

Vue.filter('formatPercentageTwoDecimals', function(value){
	if (typeof value == 'undefined' ||  value === false || value === null  ) return '0.00%';
	let result = Math.round(value * 1e2) / 1e2;
	return result.toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2}) + '%'
});

Vue.filter('formatLocalDateTimeLong', function(value,timeFlag){
	if (!value) return '';
	if (timeFlag) {
		return moment.utc(value).local().format('dddd, MMMM DD, YYYY');
	} else {
		return moment.utc(value).local().format('dddd, MMMM DD, YYYY h:mm:ss A');
	}
});

Vue.filter('formatDateTimeCustom', function(value, format){
	if (typeof value == 'undefined' ||  value === false || value === null || value === '' ){
		return '';
	} else {
		return moment.utc(value).local().format(format);
	}
});

Vue.filter('formatDateCustom', function(value, format){
	if (typeof value == 'undefined' ||  value === false || value === null  ){
		return '';
	} else {
		return moment(value).format(format);
	}
});

Vue.filter('userlocalTime',function(timeZone,format){
	return moment.tz(timeZone).format(format)+` ${moment.tz(timeZone).zoneAbbr()}` ;
});

Vue.filter('formatCharmDateTimeCustom', function(value, dateFormat = 'MMM DD, YYYY, hh:mma', timeFormat = 'hh:mma'){
	if (!value || !moment(value).isValid()){
		return '';
	}
	const today = moment().format('MM/DD/YYYY');
	const yesterday = moment().subtract(1, "day").format('MM/DD/YYYY');
	if (today === moment(value).format('MM/DD/YYYY')) {
		return `Today, ${moment.utc(value).local().format(timeFormat)}`;
	} else if (yesterday === moment(value).format('MM/DD/YYYY')) {
		return `Yesterday, ${moment.utc(value).local().format(timeFormat)}`;
	}
	return moment(value).utc().local().format(dateFormat);
});

Vue.filter('formatCharmDateTime', function(value, dateFormat = 'MMM DD, YYYY, hh:mma', timeFormat = 'hh:mma'){
	if (!value || !moment(value).isValid()){
		return '';
	}
	const today = moment().format('MM/DD/YYYY');
	const yesterday = moment().subtract(1, "day").format('MM/DD/YYYY');
	if (today === moment(value).format('MM/DD/YYYY')) {
		return `Today, ${moment(value).utc().local().format(timeFormat)}`;
	} else if (yesterday === moment(value).format('MM/DD/YYYY')) {
		return `Yesterday, ${moment(value).utc().local().format(timeFormat)}`;
	}
	return moment(value).utc().local().format(dateFormat);
});

// Assume Jan - 0th, Dec - 11th
Vue.filter('convertMonthNumberToName', function(value) {
	if(!value) return ''; 
	return moment.months(value);
});

Vue.filter('EmptyStringFiller', function(value){
	return value ? value : '-----';
});

Vue.filter('RoundNumber', function(value, decimalPlaces = 2) {
	if(!value) return ''; 
	return ( Math.round(value * 1e2) / 1e2 ).toFixed(decimalPlaces);
});
Vue.filter('numTowords',function(value){
	return value ? numTowords(value) : ''
})

Vue.filter('removeHtml', function(str,decodeRequired){
	if (typeof str === 'undefined' || str === null) {
		return '';
	}
	if(decodeRequired){
		str = htmlEntities.decode(str);
	}

	//Before remove the tags we need to preserve the links..
	str = str.split((/<a href="([^"]*)/)).join('').replaceAll('\">',',');
	
	//removing the html tags <>
	return (str + '').replace( /(<([^>]+)>)/ig, '');
});


Vue.filter('formatAddress', function(value, separator = ',', showAddress = false){

	const formatSeparator = separator === ',' ? `${separator} ` : ` ${separator} `

	const city = value?.city ? value?.city : ''
	const state = value?.state ? value?.state : ''
	let country = value?.country ? parseCountry(value.country) : ''
	const zip = value?.zip ? value?.zip : ''
	const address = showAddress && value?.address ? value?.address : ''
	const address2 = value?.address2 ? value?.address2 : ''

	switch(separator) {
		case '-':
			return [city, state, country, address, address2].filter(item => item !== "").join(formatSeparator);
		case '^':
			return [city, address].filter(item => item !== "").join(' - ');
		default:
			country = zip ? country + ' ' + zip : country;
			return [address, city, state, country].filter(item => item !== "").join(formatSeparator);
	}
});


Vue.filter('changeDateTimeFormat', function(value,format='MM/DD/YYYY [at] h:mm a'){
	if (!value) return '';
	return moment(value).format(format);
});
Vue.filter('truncateText', function(value, length){
	if (!value) return '';
	return value.length > length ? value.slice(0, length) + '...' : value;
});

Vue.filter('pluralize', function(singularWord, count){
	return count > 1 ? PLURALS[singularWord] : singularWord;
});

Vue.filter('truncateText', function(value, length){
	if (!value) return '';
	return value.length > length ? value.slice(0, length) + '...' : value;
});

Vue.filter('capitalizeEachWord', function(value){
	if (!value) return '';
	return value
	  .split(' ')
	  .map(word => word.charAt(0).toUpperCase() + word.slice(1))
	  .join(' ');
});

Vue.filter('replaceUnderscoreWithSpace', function(value){
	if (!value) return '';
	return value.replace(/_/g, ' ');
});


var htmlEntities = require("html-entities")