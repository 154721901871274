import api from '../../assets/api.js';

class BillingProfile {

  constructor() {
    this.namespaced = true;

    this.state = {
      billingProfiles: [],
      paymentMethods: []
    };

    this.getters = {
      billingProfiles: state => state.billingProfiles,
      paymentMethods: state => state.paymentMethods,
      currentBillingProfile: state => (contact_id, property_id) => state.billingProfiles.find(p => p.contact_id === contact_id && p.property_id === property_id)
    }

    this.mutations = {
      setBillingProfiles: (state, payload) => state.billingProfiles = payload.billingProfiles,
      setPaymentMethods: (state, payload) => state.paymentMethods = payload.paymentMethods,
      resetBillingProfiles: (state, payload) => state.billingProfiles = [],
    }

    this.actions = {
      async getBillingProfiles({getters, commit}, payload) {
        let contact = payload?.contact;

        try {
          let r = await api.get(this.$app, api.CONTACTS + contact?.id + '/billing-profile')
          commit('setBillingProfiles', {
            billingProfiles: r?.billingProfiles
          });
          return r;
        } catch (error) {
          throw error;
        }
      },

      async updateBillingProfiles({getters, commit, dispatch}, payload) {
        let { contact, data } = payload;

        try {
          let r = await api.put(this, api.CONTACTS + contact?.id + '/billing-profile/' + data?.id , data)
          return r;
        } catch (error) {
          throw error;
        }
      },

      async setDefaultBillingProfileForLease({getters, commit, dispatch}, payload) {
        let { contact, lease, dryrun } = payload;

        try {
          let r = await api.put(this, api.CONTACTS + contact?.id + '/lease/' + lease?.id + '/reset-billing-profile', payload);
          return r;
        } catch (error) {
          throw error;
        }
      },

      async fetchContactPaymentMethods({ commit, state }, data) {
        const { contactID, propertyID } = data;
        if(contactID) {
          const r = await api.get(this.$app, api.CONTACTS + contactID + "/payment-methods", {
            property_id: propertyID,
          });
          commit("setPaymentMethods", { paymentMethods: r.payment_methods });
        }
      },
      async getContactLeases({ commit, state }, data) {
        const { contactID, propertyID } = data;
        let r = await api.get(this, api.CONTACTS +  contactID + '/leases', { property_id: propertyID });
        return r;
      },
    }
  }
}


export default BillingProfile;
