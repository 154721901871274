const types = {
	CLEAR_CONFIRMATION:'CLEAR_CONFIRMATION',
	SHOW_CONFIRMATION: 'SHOW_CONFIRMATION',
	SAVE_TIMEOUT: 'SAVE_TIMEOUT',
	SETUP_CONFIRMATION: 'SETUP_CONFIRMATION'
}

class Confirmation {
	constructor(){
		this.namespaced = true;
		this.state = {
			showConfirmation: false,
			message: '',
			activity_id: '',
			can_undo: 0,
			timeout: null
		};
		this.getters = {
			showConfirmation: state => state.showConfirmation,
			getConfirmationMessage: state => state.getConfirmationMessage,
			getConfirmationUndo: state => state.getConfirmationUndo,
			getConfirmationActivityId: state => state.getConfirmationActivityId
		};
		this.actions = {
			setUpConfirmation({commit}, payload){
				commit(types.SHOW_CONFIRMATION, payload);
				var timeout = window.setTimeout(() => {
					commit(types.CLEAR_CONFIRMATION);
				}, 6000);
				commit(types.SAVE_TIMEOUT, timeout);
			},
			clearConfirmation({commit}){
				commit(types.CLEAR_CONFIRMATION);				
			},
			showConfirmation({commit}, payload){
				commit(types.SHOW_CONFIRMATION, payload);
			},
			saveTimeout({commit}, payload){
				commit(types.SAVE_TIMEOUT, payload);
			}
		};
		this.mutations = {
			[types.CLEAR_CONFIRMATION](state){
				state.showConfirmation = false;
				state.message = null;
				state.activity_id = null;
				state.can_undo = null;
				window.clearTimeout(state.timeout);
			},
			[types.SHOW_CONFIRMATION](state, payload){
				state.showConfirmation = true;
				state.message = payload.message;
				state.activity_id = payload.activity_id;
				state.can_undo = payload.can_undo;
			},
			[types.SAVE_TIMEOUT](state, payload){
				state.timeout = payload;
			}
		}
	}
}

export default Confirmation;