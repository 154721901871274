export default {
	TENANTS: 'companies/:company_id/tenants/',
	TENANT_GET_LEASES: 'companies/:company_id/tenants/get-leases/',
	TENANTS_VERIFY_EMAIL: 'companies/:company_id/tenants/verify-email/',
	SAVE_TENANT: 'companies/:company_id/tenants/save-tenant/',
	SAVE_TENANT_ORDER: 'companies/:company_id/tenants/save-order/',
	TENANT_SEND_WELCOME_EMAIL: 'companies/:company_id/tenants/resend-welcome-email/',
	TENANTS_OMNI: 'companies/:company_id/tenants/onmi-search/',

	NATIONAL_ACCOUNT :'companies/:company_id/national-accounts/',
	PROMOTION : 'companies/:company_id/national-accounts/national_account_discount',
	LEASE_DEPARTMENT :'companies/:company_id/national-accounts/lease_department',	
	KEY_STATISTICS : 'companies/:company_id/national-accounts/national_account_key_statistics'
}
