export default {
  charmNotification: {
    id: "",
    status: "",
    phone_number: "",
    contact_unknown: "",
    contact_id: "",
    contact_name: "",
    contact_status: "",
    contact_phone: "",
    contact_email: "",
    contact_address: "",
    contact_access_codes: [],
    property_id: "",
    contact_units: [
      {
        id:"",
        type: "",
        number: "",
        address: "",
        status: ""
      }
    ],
    selected_contact_id: null,
    selected_contact_name: null,
    selected_contact_status: null,
    selected_contact_phone: null,
    selected_contact_email: null,
    selected_contact_address: null,
    selected_contact_access_codes: null,
    selected_contact_units: null,
    communication_type: "",
    communication_time: "",
    communication_property: "",
    communication_source: "",
    call_back_time: "",
    call_duration_time: "",
    hold_duration_time: "",
    reservation_id: "",
    reservation_space_information: "",
    reservation_move_in_date: "",
    reservation_code: "",
    reservation_in_store_price: "",
    reservation_web_only_price: "",
    phone_number_added_to_selected_contact: false,
    contact_added_to_selected_contact: false,
    time: "",
    adding_in_progress: false,
    switching_in_progress: false,
    voicemail_url: '',
    playback_url: '',
    alternate_contacts: [],
    alternate_phones: [],
    loading: false,
  },

  missedCallNotifications: [],
  missedCallNotificationsCount: 0,
  reservationsNotifications: [],
  reservationsNotificationsCount: 0,
  voicemailsNotifications: [],
  voicemailsNotificationsCount: 0,
  listViewItems: [],
  charmNotificationType: "",
  charmNotificationTitle: "",
  isCharmNotificationToastOpen: false,
  charmNotificationView: "list",
  charmNotificationCount: 0,
  searchedContact:{},
  searchedContactDetails:{}
};
