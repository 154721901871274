import api from "../../../assets/api.js";
export default {
  async setToDefaultView({ commit }, data) {
    let url = '';
    switch(data.type) {
      case 'reservations' : url = api.CHARM_RESERVATION_BY_ID; break;
      case 'missed calls' : url = api.CHARM_MISSED_CALL_BY_ID; break;
      case 'voicemails' : url = api.CHARM_VOICEMAIL_BY_ID; break;
    }
    const ids = data.properties?.length ? data.properties?.map((p) => p.property_id)?.filter((id) => id)?.join(',') : [];
    const query = ids.length > 0 ? `?properties=${ids}` : '';
    await api.get(this.$app, url + data.id + query).then(response => {
      commit("setToDefaultView", response);
    })
    .catch(e => {
      console.log(e);
      return {type: 'error', msg: 'Please try again.'}
    });
  },
  showCharmNotificationToast({ commit }, data) {
    commit("setShowCharmNotification", data);
  },
  setToListView({ commit }, title) {
    commit("setToListView", title);
  },

  setFollowUpTimer({ commit }) {
    commit("setFollowUpTimer");
  },

  setCallBackTimer({ commit }) {
    commit("setCallBackTimer");
  },
  saveSelectedContact({commit,dispatch}, payload) {
    dispatch('getDetailsOfSearchedContact',payload);
    commit('saveSelectedContact', payload)
  },
  removeSelectedContact({commit}) {
    commit('removeSelectedContact')
  },
  async getReservationNotifications({ commit }, properties = []) {
    const ids = properties?.length ? properties?.map((p) => p.property_id)?.filter((id) => id)?.join(',') : [];
    const query = ids.length > 0 ? `?properties=${ids}` : '';
    await api
      .get(this.$app, api.CHARM_RESERVATIONS + query)
      .then(res => {
        commit("setReservationNotifications", res);
      })
      .catch(e => {
        console.log(e);
      });
  },

  async getMissedCallsNotifications({ commit }, properties = []) {
    const ids = properties?.length ? properties?.map((p) => p.property_id)?.filter((id) => id)?.join(',') : []
    const query = ids.length > 0 ? `?properties=${ids}` : '';
    await api
      .get(this.$app, api.CHARM_MISSED_CALLS + query)
      .then(res => {
        commit("setMissedCallNotifications", res);
      })
      .catch(e => {
        console.log(e);
      });
  },

  async getVoicemailNotifications({ commit }, properties = []) {
    const ids = properties?.length ? properties?.map((p) => p.property_id)?.filter((id) => id)?.join(',') : []
    const query = ids.length > 0 ? `?properties=${ids}` : '';
    await api
      .get(this.$app, api.CHARM_VOICE_MAILS + query)
      .then(res => {
        commit("setVoiceMailNotifications", res);
      })
      .catch(e => {
        console.log(e);
      });
  },

  getNotificationDataFromStore({ commit }, data) {
    commit("notificationDataFromStore", data);
  },

  async readReservationNotifications({ commit, dispatch }, data) {
    const { notification, properties } = data;
    commit('setReadNotification', notification);
    await api
      .put(this.$app, api.CHARM_RESERVATIONS_READ + "/" + notification.id + "/read")
      .then(
        )
      .catch(e => {
        console.log(e);
      });
      dispatch("getReservationNotifications", properties);
  },

  async readMissedCallsNotifications({ commit, dispatch }, data) {
    const { notification, properties } = data;
    commit('setReadNotification', notification);
    await api
      .put(this.$app, api.CHARM_MISSED_CALLS_READ + "/" + notification.id + "/read")
      .then()
      .catch(e => {
        console.log(e);
      });
      dispatch("getMissedCallsNotifications", properties);
  },

  async readVoicemailNotifications({ commit, dispatch }, data) {
    const { notification, properties } = data;
    commit('setReadNotification', notification);
    await api
      .put(this.$app, api.CHARM_VOICE_MAILS_READ + "/" + notification.id + "/read")
      .then()
      .catch(e => {
        console.log(e);
      });
      dispatch("getVoicemailNotifications", properties);
  },

  addAdditionalContact({commit}, contact){
    commit('setAdditionalContact', contact);
  },

  addAdditionalPhone({commit}, id){
    commit('setAdditionalPhone', id);
  },

  // For New Lead is 'Unknown contact', so adding additional Phone number
  async addAdditionalContactForNewLead({commit}, payload) {
    let data = { phone: payload?.caller?.phone_number, sms: 1, type: "Cell" };
    let notify = {msg: '', type: ''};
    await api.put(this, api.CHARM_CONTACT + payload.searchedContact.id + '/additional-phone', data).then(r => {
      commit('setAdditionalContact', {id: payload.caller.contact_id});
      notify.type = 'success';
      notify.msg = `${payload.caller.contact_name } has been added as an additional profile to ${payload.searchedContact.first} ${payload.searchedContact.last}`;
    }).catch(e => {
      console.log(e);
      notify.type = 'error';
      notify.msg = 'Please try again.';
    })
    return notify;
  },
  getDetailsOfSearchedContact({commit},payload){
    if (payload?.id) {
      api.get(this, api.CHARM_CONTACT+payload.id).then(r =>{
        commit('setDetailsOfSearchedContact',r);
     }).catch(e => {
       console.log(e);
     });
    }
  },
  refreshContactUnits({commit},payload){
    api.get(this, api.CHARM_CONTACT+payload).then(r =>{
      commit('setContactUnitsNotification',r);
   }).catch(e => {
     console.log(e);
   });
  },

  setLoadingAction({commit}, payload) {
    commit('setLoadingAction', payload)
  },

  stopLoadingAnimation({commit}) {
    commit('stopLoadingAnimation')
  },

};
