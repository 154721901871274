import api from '../../assets/api.js';
import Vue from 'vue';
const types = {
    SET_TASKS: 'SET_TASKS',
    SET_EVENTS: 'SET_EVENTS',
    SET_EVENT_TYPES: 'SET_EVENT_TYPES'
};

class ProfileTasks {
	constructor(){
		this.namespaced = true;
		this.state = {
            tasks: [],
            events: [],
            eventTypes: []
		};
		this.getters = {
            tasks: state => state.tasks,
            events: state => state.events,
            eventTypes: state => state.eventTypes

		};
		this.actions = {
            getEvents({commit}){
                api.get(this.$app, api.Events).then( payload => {
                    commit(types.SET_EVENTS, payload.events);
                }).catch(e => { console.log('GET ERROR ', e); });
            },
			getTasks({ commit }, data = {}){
                commit(types.SET_TASKS, []);
                // let path = data.params ? api.TASKS.slice(0, -1) + data.params : api.TASKS;
                let url = api.TASKS + data.id;
                if(data.property_id){
                    url = `${url}?property_id=${data.property_id}`
                }
                if(data.role_ids && data.role_ids.length > 0){
                    url = url + data.role_ids.reduce((a,b) => a + `&role_ids[]=${b}`, '');
                }
				return api.get(this.$app, url).then(payload => {
                    commit(types.SET_TASKS, payload.tasks);
                    return payload;
                });
            },
            getEventTypes({ commit }, type = ''){
                commit(types.SET_EVENT_TYPES, []);
                api.get(this.$app, api.EVENTS + 'event-types' + (type ? ('?type=' + type) : '')).then( payload => {
                    commit(types.SET_EVENT_TYPES, payload.event_types);
                })
            },
            resetTasks({commit}){
                commit(types.SET_TASKS, []);
            },
            async removeOverlock({commit, dispatch}, payload) {
                await api.delete(this.$app, api.UNITS + payload.unit_id + "/overlock" + (payload.lease_id ? ('?lease_id=' + payload.lease_id) : '')).then(r => {});
            },
            async overlockSpace({commit, dispatch}, payload) {
                await api.post(this.$app, api.UNITS + payload.unit_id + "/overlock", { lease_id: payload.lease_id }).then(r => {});
            },
            async dismissTask({commit, dispatch}, payload){
                await api.put(this.$app, api.TODOS + 'dismiss', { todos: [payload.todo] }, 'none').then(r => {});
            },
		};
		this.mutations = {
			[types.SET_TASKS](state, payload) {
				state.tasks = payload;
            },
            [types.SET_EVENTS](state, payload) {
				state.events = payload;
			},
            [types.SET_EVENT_TYPES](state, payload) {
				state.eventTypes = payload;
			}
		}
	}
}

export default ProfileTasks;
