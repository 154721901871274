export default {
  isCharmCallToastOpen: false,
  type: "mini-charm",
  view: "mini-charm",
  activeCallId: "",
  activeCallContactName: "",
  incomingNotifications: [],
  addingIncomingNotificationInProgress: false,
  confirmation: "",
  playbackTime: 0,
  playbackInterval: null,
  voicemailTime: 0,
  voicemailInterval: null,
  holdTimeMinutes: 0,
  holdTime: 0,
  holdInterval: null,
  title: "Incoming Call...",
  communicationTitle: "Call (In)",
  communicationIcon: "mdi-phone-incoming",
  durationTimeMinutes: 0,
  durationTime: 0,
  durationInterval: null,
  notification: {
    id: "",
    status: "",
    phone_number: "",
    contact_unknown: "",
    contact_id: "",
    contact_name: "",
    contact_status: "",
    contact_phone: "",
    contact_email: "",
    contact_address: "",
    contact_access_codes: [],
    property_id: "",
    contact_units: [
      {
        id:"",
        type: "",
        number: "",
        address: "",
        status: ""
      }
    ],
    communication_type: "",
    communication_time: "",
    communication_property: "",
    communication_source: "",
    call_back_time: "",
    call_duration_time: "",
    hold_duration_time: "",
    reservation_id: "",
    reservation_space_information: "",
    reservation_move_in_date: "",
    reservation_code: "",
    reservation_in_store_price: "",
    reservation_web_only_price: "",
    selected_contact_id: null,
    selected_contact_name: null,
    selected_contact_status: null,
    selected_contact_phone: null,
    selected_contact_email: null,
    selected_contact_address: null,
    selected_contact_access_codes: null,
    selected_contact_units: null,
    phone_number_added_to_selected_contact: false,
    contact_added_to_selected_contact: false,
    time: "",
    adding_in_progress: false,
    switching_in_progress: false,
    voicemail_url: '',
    playback_url: '',
    is_interacted: false,
    alternate_contacts: [],
    alternate_phones: [],
    loading: false,
    twilioDetails: {},
  },
  twilioCallDetails: [],
  outgoingPhoneNumber: '',
  searchedContact: {},
  searchedContactDetails:{},
  callWrapData:"",
  isNotificationActive: false,
  ivrPaymentType:"",
 
  IvrPaymentEnable:false,
  ivrPaymentMethod:"",
  enableIvrProcessPayment:false,
  paymentConnectorResponse:"",
  ivrPaymentButtonLoading:false, 
  ivrTimer:'',
  ivrPaymentSessionStarted:false
};
