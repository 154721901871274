export default {
	PROPERTY_IMAGES: 'companies/:company_id/properties/get-images/',
	PROPERTIES: 'companies/:company_id/properties/',
	NON_HB_PROPERTIES: 'companies/:company_id/non-hummingbird-properties/',
	PROPERTIES_GET_NEIGHBORHOODS: 'companies/:company_id/properties/locations/neighborhood/',
	PROPERTY_UNITS: 'companies/:company_id/properties/view/units',
	PROPERTY_SAVE_CONNECTION: 'companies/:company_id/properties/save-connection/',
	PROPERTY_SET_TEMPLATE: 'companies/:company_id/properties/set-template/',
	PROPERTY_CHECKLISTS: 'companies/:company_id/properties/checklists/',
	PROPERTY_CHECKLISTS_ORDER: 'companies/:company_id/properties/checklist-order/',
	PROPERTY_TEMPLATE: 'companies/:company_id/properties/template/'

	//PROPERTY_APPLICATION_CONFIG: 'properties/application-config/',
	// EDIT_PROPERTY: 'properties/edit-property/',
	// SAVE_HOURS: 'properties/save-hours/',
	// DELETE_HOURS: 'properties/delete-hours/',
	// PROPERTY_SEARCH: 'properties/search',
	// PROPERTY_HOURS: 'properties/get-hours/',
	// PROPERTIES_SAVE: 'properties/save/',
	// PROPERTIES_LIST: 'properties/list/',
	// PROPERTY_GET_CONNECTIONS: 'properties/get-connection/',
	// PROPERTY_DELETE_CONNECTION: 'properties/delete-connection/',
}
