import api from '../../assets/api.js';
import Vue from 'vue';

const types = {
    SET_TRIGGERS: 'GET_TRIGGERS',
    SET_ACTION_TYPES: 'SET_ACTION_TYPES',
    SET_TRIGGER_GROUPS: 'GET_TRIGGER_GROUPS',
    SET_ACTIVE_GROUP: 'SET_ACTIVE_GROUP',
    SET_ACTIVE_TRIGGER: 'SET_ACTIVE_TRIGGER',
    DELETE_TRIGGER: 'DELETE_TRIGGER'
}

class Triggers {
    constructor() {
        this.namespaced = true;
        this.state = {
            actionTypes: [],
            triggers: [],
            groups: [],
            active: {
                name: '',
                Properties: []
            },
            activeTrigger: null,
            selected: null,
            hasAdd: false,
            hasDelete: false,
            hasEdit: false
        };
        this.getters = {
            actionTypes: state => state.actionTypes,
            triggers: state => state.triggers,
            groups: state => state.groups,
            active: state => state.active,
            activeTrigger: state => state.activeTrigger,
            selected: state => state.selected,
            hasAdd: state => state.hasAdd,
            hasDelete: state => state.hasDelete,
            hasEdit: state => state.hasEdit
        };
        this.actions = {
            getActionTypes({commit}) {
                api.get(this.$app, api.TRIGGERS + "action-types").then(r => {
                    commit(types.SET_ACTION_TYPES, r.action_types);
                });
            },
            getTriggers({ commit }) {
                api.get(this.$app, api.TRIGGERS).then(r => {
                    //commit(types.SET_TRIGGERS, r.triggers);
                });
            },
            // getGroups({ commit }) {
            //     api.get(this.$app, api.TRIGGERS + "groups").then(r => {
            //         commit(types.SET_TRIGGER_GROUPS, r.trigger_groups);
            //     });
            // },
            // getTriggersByGroup({ commit }, payload) {
            //     api.get(this.$app, api.TRIGGERS + "?trigger_group_id=" + payload.id).then(p => {
            //         commit(types.SET_TRIGGERS, p.triggers);
            //     });
            // },
            getGroupById({ commit, dispatch }, payload) {
                commit(types.SET_ACTIVE_GROUP, payload);
            },
            getTriggerById({commit, dispatch}, payload) {
                commit(types.SET_ACTIVE_TRIGGER, payload);
            },
            async addGroup({commit, dispatch}, payload) {
                await api.post(this.$app, api.TRIGGERS + "groups/", payload).then(r => {
                   // dispatch('getGroups');
                });
            },
            async editGroup({commit, dispatch}, payload) {
                await api.put(this.$app, api.TRIGGERS + "groups/" + payload.id, payload).then(r => {
                   // dispatch('getGroups');
                });
            },
            // addTrigger({commit, dispatch}, payload) {
            //     api.post(this.$app, api.TRIGGERS, payload).then(r => {
            //         dispatch('getTriggersByGroup', {id: payload.trigger_group_id});
            //     });
            // },
            // editTrigger({commit, dispatch}, payload) {
            //     api.put(this.$app, api.TRIGGERS + payload.id, payload).then(r => {
            //         dispatch('getTriggersByGroup', {id: payload.trigger_group_id});
            //     });
            // },
            // deleteTrigger({commit, dispatch}, payload) {
            //     api.delete(this.$app, api.TRIGGERS + payload.id).then(r => {
            //         dispatch('getTriggersByGroup', {id: payload.trigger_group_id});
            //       //  dispatch('getGroups');
            //     });
            // },
            
            // duplicateGroup({commit, dispatch}, payload) {
            //     api.post(this.$app, api.TRIGGERS + "/groups/" + payload.id +"/duplicate").then(r => {
            //       //  dispatch('getGroups');
            //     });
            // }
        };
        this.mutations = {
            [types.SET_ACTIVE_TRIGGER](state, payload){
                let activeTrigger = state.triggers.find(t => t.id === payload.id);
                state.activeTrigger = activeTrigger ? activeTrigger : {};
            },
            [types.SET_ACTION_TYPES](state, payload) {
                state.actionTypes = payload;
            },
            [types.SET_TRIGGERS](state, payload) {
                state.triggers = payload;
            },
            [types.SET_TRIGGER_GROUPS](state, payload) {
                state.groups = payload;
            },
            [types.SET_ACTIVE_GROUP](state, payload) {
                let active = state.groups.find(o => o.id === payload.id);
                state.active = active ? active : {
                    name: '',
                    Properties: []
                };
            }
        }
    }
}

export default Triggers;
