
export default {
  setProperties(state, data) {
    state.properties = [];
    state.properties = [...data];
    state.charmSubscribedProperties = [];
    state.charmSubscribedProperties = state.properties.length ? state.properties.filter(p => p.mini_charm_enabled === true) : [];
    if (state.charmSubscribedProperties.length && state.filteredProperties.length) {
      state.charmSubscribedAndFilteredProperties = state.charmSubscribedProperties?.filter(f => state.filteredProperties?.some(s => s === f.property_id)) || [];
    }
  },

  setFilteredProperties(state, data) {
    state.filteredProperties = [];
    state.filteredProperties = [...data];
    if (state.charmSubscribedProperties.length && state.filteredProperties.length) {
      state.charmSubscribedAndFilteredProperties = state.charmSubscribedProperties?.filter(f => state.filteredProperties?.some(s => s === f.property_id)) || [];
      state.charmSubscribedNotSelectedProperties = state.charmSubscribedProperties?.filter(f => !state.filteredProperties?.some(s => s === f.property_id)) || [];      
    }
  },

  setUserDetails(state, payload) {
    state.userDetails = payload;
  }

};
