export default {
    REPORT_COLLECTIONS: 'companies/:company_id/reports/collections-details',
    REPORT_PINNED: 'companies/:company_id/reports/pinned-status',
    REPORTS: 'companies/:company_id/reports/',
    SEARCH_REPORT: 'companies/:company_id/reports/search-all-reports?keyword=',
    REPOPRT_LIBRARY_SCHEDULED_REPORTS: 'companies/:company_id/reports/schedule-report',
    REMOVE_REPORT: 'companies/:company_id/reports/remove-addons-reports',
    ADD_REPORT: 'companies/:company_id/reports/add-addons-collections'
}

