const types = {
    SET_LABEL: 'SET_LABEL',
    SET_ACTIONS: 'SET_ACTIONS',
    SET_VALUE: 'SET_VALUE'
}

class Snackbar {
	constructor(){
		this.namespaced = true;
		this.state = {
            absolute: false,
            bottom: false,
            color: null,
            left: false,
            multiLine: false,
            right: false,
            timeout: 0,
            top: false,
            value: null,
            vertical: false,
            label: null,
            simple: true,
            actions: []
		},
		this.getters = {
			label: state => state.label,
            actions: state => state.actions,
            absolute: state => state.absolute,
            bottom: state => state.bottom,
            color: state => state.color,
            left: state => state.left,
            multiLine: state => state.multiLine,
            right: state => state.light,
            timeout: state => state.timeout,
            top: state => state.top,
            value: state => state.value,
            vertical: state => state.vertical,
            simple: state => state.simple
		},
		this.actions = {
			setLabel({commit}, payload){
				commit(types.SET_LABEL, payload);
            },
            setActions({state, commit, rootGetters}, payload) {
                //Commented against INC-4471
                // let isHBUser = rootGetters['authenticationStore/isHBUser']
                // if(isHBUser) {
                //     let facilitiesList = rootGetters['propertiesStore/filtered'];
                //     if(facilitiesList.length === 1) {
                //         if(facilitiesList[0].is_day_closed) {
                //             payload.unshift({
                //                 command: 'HB-Navigation:CloseOfDay',
                //                 title: 'Undo Close of Day',
                //                 payload: 'undoCloseOfDay'
                //             })
                //         } else {
                //             payload.unshift({
                //                 command: 'HB-Navigation:CloseOfDay',
                //                 title: 'Close of Day',
                //                 payload: 'closeOfDay'
                //             })
                //         }
                //     } else {
                //         payload = payload.filter(action => {
                //             return action.payload != 'closeOfDay' || action.payload != 'undoCloseOfDay'
                //         });
                //     }
                // }
                
                commit(types.SET_ACTIONS, payload);
            },
            setValue({commit}, payload) {
                commit(types.SET_VALUE, payload);
            }
		},
		this.mutations = {
			[types.SET_LABEL](state, payload){	
				state.label = payload;
            },
            [types.SET_ACTIONS](state, payload){	
				state.actions = payload;
            },
            [types.SET_VALUE](state, payload){	
				state.value = payload;
			}
		}
	}
}

export default Snackbar;