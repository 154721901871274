import api from '../../assets/api.js';
class Automation {
  constructor() {
    this.namespaced = true;

    this.state = {
      allWorkflows: [],
      allAutomationTypes: [],
      currentTimeLine: [],
      missingActions: [],
      notification: {
        type: '',
        msg: ''
      },
      triggerActionDependencyTypes: [],
      timeFrames: [
        { id: 1, slug: 'immediately', content: 'Immediately' },
        { id: 2, slug: 'days', content: 'Day(s)' },
      ],
      allEvents:[]
    };

    this.getters = {
      getAllWorkflows: (state) => state.allWorkflows,
      getAllAutomationTypes: (state) => state.allAutomationTypes,
      getCurrentTimeLine: (state) => state.currentTimeLine,
      getMissingActions: (state) => state.missingActions,
      getNotification: (state) => state.notification,
      getTriggerActionDependencyTypes: (state) => state.triggerActionDependencyTypes,
      getTimeFrames: (state) => state.timeFrames,
      getAllEvents: (state) =>  state.allEvents 
    };

    this.mutations = {
      setCurrentTimeLine(state, payload) {
        state.currentTimeLine = payload;
      },
      setMissingActions(state, payload){
        state.missingActions = payload;
      },
      setAllAutomationTypes (state,payload){
        state.allAutomationTypes=payload;
      },
      setNotification(state, payload) {
        state.notification = payload;
      },
      setAllWorkflows(state, payload) {
        state.allWorkflows = payload;
      },
      setTriggerActionDependencyTypes(state, payload) {
        state.triggerActionDependencyTypes = payload;
      },
      setAllEvents(state, payload) {
        state.allEvents = payload;
      }
    };

    this.actions = {
      async setStates({getters,commit}, data) {
        try {
          const {state}=data;           
          commit('setCurrentTimeLine', getters.getCurrentTimeLine);
        } catch (error) {
          console.error('Error fetching currentTimeLine:', error);
          commit('setNotification', {type: 'error', msg: error});
        }
      },
      async fetchAllAutomations({ getters, commit }, data) {
        try {
          let response = await api.get(this.$app, api.AUTOMATION);
          commit('setAllAutomationTypes', response.automations);
        } catch (error) {
          commit('setNotification', {type: 'error', msg: error});
        }
      },
      // func to get the details of all workflows
      async fetchAllWorkflows({ getters, commit }, data) {
        let { automation_type_id, property_id = null } = data;
        try {
          let res = await api.get(this.$app, `${api.AUTOMATION}/${automation_type_id}`, { ...(property_id ? { property_id } : {}) });
          commit('setAllWorkflows', res.automation_hierarchy);
        } catch (error) {
          commit('setNotification', {type: 'error', msg: error});
        }
      },
      // func to get the details of all missing actions
      async fetchAllMissingActions({ getters, commit }, data) {
        let { automation_type_id, property_id = '' } = data;
        try {
          let res = await api.get(this.$app, `${api.AUTOMATION}/${automation_type_id}/missing-actions?property_id=${property_id}`);
          commit('setMissingActions', res.missing_actions);
        } catch (error) {
          commit('setNotification', {type: 'error', msg: error});
        }
      },
      // func to set the automation notifications
      setNotification({ getters, commit }, notification){
        let {type = '', msg = ''} = notification;
        commit('setNotification', {type: type, msg: msg});
      },
      // func to set the trigger action dependencies types
      async fetchTriggerActionDependencyTypes({ getters, commit }, data){
        let { automation_category_id = null } = data;
        try {
          let res = await api.get(this.$app, `${api.TRIGGERS}dependency-types/${automation_category_id}`);
          commit('setTriggerActionDependencyTypes', res.dependency_types);
        } catch (error) {
          commit('setNotification', {type: 'error', msg: error});
        } 
      },
      // func to set the trigger action dependencies types
      async fetchAllEvents({ getters, commit }, data){ 
        let { trigger_group_id = null, trigger_id =null } = data;
        try {
          let res = await api.get(this.$app, `${api.TRIGGERS}groups/${trigger_group_id}/all-actions?trigger_id=${trigger_id}`);
          commit('setAllEvents', res.allEvents);
        } catch (error) {
          commit('setNotification', {type: 'error', msg: error});
        } 
      }

    };
  }
}
export default Automation;
