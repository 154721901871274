const types = {
	SET_STATUS: 'SET_STATUS',
	SET_MESSAGE: 'SET_MESSAGE',
	SET_CODE: 'SET_CODE'
};

class Status {
	constructor(){
		this.namespaced = true;
		this.state = {
			status: null,
			message: '',
			code: 0
		};
		this.getters = {
			getStatus: state => state.status,
			getMessage: state => state.message,
			getCode: state => state.code
		};
		this.actions = {
			setStatus({commit}, payload){
				commit(types.SET_STATUS, payload.status)
				commit(types.SET_MESSAGE, payload.message)
			},
			resetStatus({commit}){
				commit(types.SET_STATUS, null)
				commit(types.SET_MESSAGE, '')
			}
		};
		this.mutations = {
			[types.SET_STATUS](state, payload){
				state.status = payload;
			},
			[types.SET_MESSAGE](state, payload){
				state.message = payload;
			},
			[types.SET_CODE](state, payload){
				state.code = payload;
			}
		}
	}
}

export default Status;