export default {
  setAllFeaturesList(state, payload) {
    state.allFeaturesList = payload;
  },
  setAddedFeaturesList(state, payload) {
    state.addedFeaturesList = payload;
  },
  setAddedFeaturesListLoading(state) {
    state.addedFeaturesListLoading = true;
    state.addedFeaturesListLoaded = false;
  },
  setAddedFeaturesListLoaded(state) {
    state.addedFeaturesListLoading = false;
    state.addedFeaturesListLoaded = true;
  },
  setUnitTypes(state, payload){
    state.unit_types = payload;
  }
};
