export default {
  isCharmCallToastOpen: state => state.isCharmCallToastOpen,
  incomingNotifications: state => state.incomingNotifications,
  type: state => state.type,
  view: state => state.view,
  activeCallId: state => state.activeCallId,
  activeCallContactName: state => state.activeCallContactName,
  confirmation: state => state.confirmation,
  title: state => state.title,
  communicationTitle: state => state.communicationTitle,
  communicationIcon: state => state.communicationIcon,
  notification: state => state.notification,
  twilioCallDetailsToUnhold: state => state.twilioCallDetails.find(t => t.id === state.notification?.id),
  activeCallTwilioDetails: state => state.twilioCallDetails.find(t => {
    if ((state.notification?.status === 'active') && (t.id === state.notification?.id)) {
      return t;
    } else if (state.incomingNotifications.length) {
      let foundIndex = state.incomingNotifications.findIndex(f => f.status === 'active');
      if ((foundIndex !== -1) && t.id === state.incomingNotifications[foundIndex].id) {
        return t;
      }
    }
  }),
  callCount: state => state.twilioCallDetails.length,
  outgoingPhoneNumber: state => state.outgoingPhoneNumber,
  searchedContact: state => state.searchedContact,
  isNotificationInteracted: state => state.notification?.is_interacted,
  isSameNotification: state => (ob) => ((state.notification?.id === ob?.id) || (state.incomingNotifications?.length && state.incomingNotifications?.some(f => f?.id === ob?.id))),
  searchedContactDetails:state => state.searchedContactDetails,
  isCallToastAvailable: state => (payload) => ((state.notification?.id === payload?.id && state.notification?.status === 'call in') || (state.incomingNotifications.length && state.incomingNotifications?.some(f => f?.id === payload?.id && f?.status === 'call in'))),
  isMissedCallToastAvailable: state => (payload)=> ((state.notification?.id === payload?.id && state.notification?.status === 'missed') || (state.incomingNotifications.length && state.incomingNotifications?.some(f => f?.id === payload?.id && f?.status === 'missed'))),
  isVoicemailToastAvailable: state => (payload)=> ((state.notification?.id === payload?.id && state.notification?.status === 'voicemail') || (state.incomingNotifications.length && state.incomingNotifications?.some(f => f?.id === payload?.id && f?.status === 'voicemail'))),
  callWrapData: state => state.callWrapData,
  isNotificationActive: state => state.isNotificationActive,
  ivrPaymentType: state => state.ivrPaymentType,
  IvrPaymentEnable: state => state.IvrPaymentEnable,
  ivrPaymentMethod: state => state.ivrPaymentMethod,
  enableIvrProcessPayment: state => state.enableIvrProcessPayment,
  paymentConnectorResponse: state => state.paymentConnectorResponse,
  ivrPaymentButtonLoading:state => state.ivrPaymentButtonLoading,
  ivrTimer: state => state.ivrTimer,
  ivrPaymentSessionStarted: state => state.ivrPaymentSessionStarted

};
