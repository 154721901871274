import api from '../../assets/api.js';
import moment from 'moment';
import Vue from 'vue';
const types = {
	SET_NOTIFICATIONS: 'SET_NOTIFICATIONS',
	READ_NOTIFICATION: 'READ_NOTIFICIATION'
}


function base64ToArrayBuffer(base64) {
  var binaryString = window.atob(base64);
  var binaryLen = binaryString.length;
  var bytes = new Uint8Array(binaryLen);
  for (var i = 0; i < binaryLen; i++) {
    var ascii = binaryString.charCodeAt(i);
    bytes[i] = ascii;
  }
  return bytes;
}


class Notifications {
	constructor() {
		this.namespaced = true;
		this.state = {
				notifications: [],
        messageCount: 0
		};
		this.getters = {
			notifications: state => state.notifications.filter(n => n.active),
      messageCount: state => state.messageCount
		};
		this.actions = {
			setNotifications({commit}, notifications) {
				// api.get(this, api.NOTIFICATIONS).then(r => {
				// 	commit(types.SET_NOTIFICATIONS, r.notifications)
				// });
			},
      async getMessageCount({commit}) {
        try {
          let resp = await api.get(this, api.CONTACTS + 'unread-interactors')
          commit('setMessageCount', resp.unreadCount)
        } catch (error) {}
      },
      setAddIncomingEmail({commit, dispatch}, payload) {
        commit('addIncomingEmail', payload); 
        setTimeout(() => {
          commit('removeNotification', 'new_email_' + payload.email_id);
          dispatch('getMessageCount');
        }, 6000);
      },
      setAddIncomingSMS({commit, dispatch}, payload) {
        commit('addIncomingSMS', payload); 
        setTimeout(() => { 
          commit('removeNotification', 'new_sms_' + payload.interaction_id);
          dispatch('getMessageCount');
        }, 6000);
      },
      setSuccessNotification({commit}, notification) {
        let payload = {};
        payload = notification;
        payload.id = notification.id + '_Success';
        payload.status = 'formSuccess';
        payload.icon = 'mdi-check';
        payload.title = 'Success';
        payload.form_errors = payload.formErrors ? payload.formErrors : [];
        commit('setFormAlertMessage', payload);
        setTimeout(() => { commit('removeNotification', notification.id); }, 6000);
      },
      setErrorNotification({commit, getters}, notification) {
        let payload = {};
        payload = notification;
        payload.id = notification.id + '_Error';
        payload.status = 'formError';
        payload.icon = 'mdi-alert';
        payload.title = 'Warning';
        payload.form_errors = payload.formErrors ? payload.formErrors : [];
        if(payload.form_errors.length == 0) {
          let index = getters.notifications.findIndex(n => n.id === notification.id);
          if(index >= 0){
            commit('clearNotification', notification.id);
          }
          return;
        }
        commit('setFormAlertMessage', payload);
      },
			readNotification({commit}, notification) {
				api.put(this.$app, api.NOTIFICATIONS + notification.id + '/read').then(() => {
					notification.status = 1;
				});
			},
      handleEventClick({commit}, notification){
        
			  
      },
      //Code added by BCT team
      setWarningNotification({commit}, notification) {
        let payload = {};
        payload = notification;
        payload.id = notification.id + '_Warning';
        payload.status = 'formWarning';
        payload.icon = 'mdi-alert';
        payload.title = 'Warning';
        payload.form_errors = payload.formErrors ? payload.formErrors : [];
        setTimeout(() => { commit('removeNotification', notification.id); }, 10000);
        commit('setAlertMessage', payload);
      },
      setValidatingNotification({ commit }, notification) {
        let payload = {};
        payload = notification;
        payload.id = notification.id + "_Open";
        payload.status = "open";
        payload.icon = "mdi-file";
        payload.title = notification.title || "Validating Spreadsheet";
        payload.loading = true;
        payload.validationText = notification.text;
        delete payload['text'];
        payload.form_errors = payload.formErrors ? payload.formErrors : [];
        commit("setAlertMessage", payload);
      },
      setRemoveNotification({ commit }, notification) {
        commit("removeNotification", notification.id);
      },
      setRemoveMergeNotification({ commit }, notification) {
        commit("removeNotification", notification.id);
      },
      setNotificationWithButton({ commit }, notification) {
        let payload = {};
        payload = notification;
        payload.id = notification.id + "_Success";
        payload.status = "formSuccess";
        payload.icon = "mdi-check";
        payload.title = "Success";
        payload.btn_text = notification.btnText || "Begin Property Setup";
        payload.subtext = notification.subtext
        payload.action = true;
        payload.form_errors = payload.formErrors ? payload.formErrors : [];
        payload.property_status = notification.property_status ||'completed_corporate_setting';
        commit("setAlertMessage", payload);
      },
      //end code
		};
		this.mutations = {

      setMessageCount(state, payload){
        state.messageCount = payload
      },
      setFormAlertMessage(state, payload) {
        let found = state.notifications.findIndex(n => n.id === payload.id);
        if(found >= 0){
          state.notifications[found].text = payload.text;
          state.notifications[found].errors = payload.form_errors;
          state.notifications[found].active = true;
        } else {
          state.notifications.push({
            id: payload.id,
            type: 'alert',
            icon: payload.icon,
            title: payload.title,
            dismissible: true,
            status: payload.status,
            loading: false,
            text: payload.text,
            errors: payload.form_errors,
            action: false,
            btn_text: false,
            active: true
          })
        }
      },

		  setAlert(state, payload) {

		    let statuses = [{
		      success: {
            icons:  'mdi-check',
            title: "Success!"
          },
		      warning: {
            icons:  'mdi-alert',
            title: "Warning"
          },
		      error: {
		        icons:  'mdi-block-helper',
            title: "Error!"
          },
        }];

		    let found = state.notifications.findIndex(n => n.id === payload.id);
        if(found >= 0){
          state.notifications[found].icon = statuses[payload.status] ? statuses[payload.status].icon: null;
          state.notifications[found].title = payload.title;
          state.notifications[found].status = payload.status;
          state.notifications[found].text = payload.text;
          state.notifications[found].active = true;
        } else {
          state.notifications.push({
            id: payload.id,
            type: 'alert',
            icon: statuses[payload.status] ? statuses[payload.status].icon: null,
            title: payload.title,
            dismissible: true,
            status: payload.status,
            loading: false,
            text: payload.text,
            action: false,
            btn_text: false,
            active: true
          })
        }


      },
			[types.SET_NOTIFICATIONS](state, notifications) {
				state.notifications = notifications;
			},

			[types.READ_NOTIFICATION](state, notification) {

			},
      removeNotification(state, id){

        let index = state.notifications.findIndex(n => n.id === id);
		    Vue.set( state.notifications[index], 'active', false);

      },
      clearNotification(state, id) {
        let index = state.notifications.findIndex(n => n.id === id);
        if(index > -1) {
          state.notifications.splice(index, 1);
        }
        // Vue.set(state.notifications, index, []);
      },

      downloadReceipt(state, payload){
        let found = state.notifications.findIndex(n => n.id === payload.id);
        let n = {
          id: payload.id,
          type: 'document_download',
          icon: 'mdi-file',
          title: 'Generating Receipt',
          dismissible: true,
          status: 'open',
          loading: true,
          text: 'We are generating your document, It should begin downloading shortly.',
          action: false,
          btn_text: false,
          active: true
        }

        if(found >= 0){
          Vue.set(state.notifications, found,n);
        } else {
          state.notifications.push(n);
        }

      }, 

      // added by BCT
      createSpaces(state, payload){
			  const found = state.notifications.findIndex(n => n.id === payload.id);
        const n = {
          id: payload.id,
          type: 'create_spaces',
          icon: 'mdi-custom-add-spaces',
          title: 'Adding Spaces',
          dismissible: true,
          status: 'open',
          loading: false,
          text: 'We are adding all of the entered spaces, this may take a few seconds.',
          action: false,
          btn_text: false,
          active: true
        }
        if(found >= 0){
          Vue.set(state.notifications, found, n);
        } else {
          state.notifications.push(n);
        }
      },
      updateCreateSpaces(state, payload){
			  const index = state.notifications.findIndex(n => n.id === payload.id);
			  if(payload.success){
          state.notifications[index].title = 'Your spaces are ready.';
          state.notifications[index].text = '';
          state.notifications[index].status = 'success';
          state.notifications[index].loading = false;
          state.notifications[index].btn_text = '';
          state.notifications[index].action = '';
          state.notifications[index].active = true;
          state.notifications[index].title_color = 'white';
        } else {
          state.notifications[index].title = 'An error occurred';
          state.notifications[index].text = payload.message;
          state.notifications[index].status = 'error';
          state.notifications[index].loading = false;
          state.notifications[index].active = true;
        }
      },
      
		  setProcessAlert(state, payload) {
		    let statuses = {
		      success: {
            icon:  'mdi-check',
            title: "Success!"
          },
		      error: {
		        icon:  'mdi-block-helper',
            title: "Error!"
          },
		      open: {
            icon:  'mdi-alert',
            title: "Warning"
          },
        };

		    let found = state.notifications.findIndex(n => n.id === payload.id);
        if(found >= 0){
          state.notifications[found].icon = statuses[payload.status] ? statuses[payload.status].icon: null;
          state.notifications[found].title = payload.title;
          state.notifications[found].status = payload.status;
          state.notifications[found].text = payload.text;
          state.notifications[found].active = true;
          state.notifications[found].loading = false;
          state.notifications[found].title_color = payload.status === 'success' ? 'white': null;
          state.notifications[found].text_color = 'white';
        } else {
          state.notifications.push({
            id: payload.id,
            type: 'process_alert',
            icon: statuses[payload.status] ? statuses[payload.status].icon: null,
            title: payload.title,
            dismissible: true,
            status: payload.status,
            loading: true,
            text: payload.text,
            action: false,
            btn_text: false,
            active: true
          })
        }
      },

      startDocumentDownload(state, payload){
        let found = state.notifications.findIndex(n => n.id === payload.id);
        let n = {
          id: payload.id,
          type: 'document_download',
          icon: 'mdi-file',
          title: payload.title ? payload.title : 'Generating Document',
          dismissible: true,
          status: 'open',
          loading: true,
          text: 'We are generating your document, it should begin downloading shortly.',
          action: false,
          btn_text: false,
          active: true
        }

        if(found >= 0){
          Vue.set(state.notifications, found,n);
        } else {
          state.notifications.push(n);
        }

      },
      startSharingReport(state, payload){
        let found = state.notifications.findIndex(n => n.id === payload.id);
        let n = {
          id: payload.id,
          type: 'share_report',
          icon: 'mdi-file',
          title: 'Sharing Report',
          dismissible: true,
          status: 'open',
          loading: true,
          text: 'We are sharing your report, it should be done shortly.',
          action: false,
          btn_text: false,
          active: true
        }

        if(found >= 0){
          Vue.set(state.notifications, found,n);
        } else {
          state.notifications.push(n);
        }

      },
      updateSharingReport(state, payload){
        let index = state.notifications.findIndex(n => n.id === payload.id);
			  if(payload.status == 'success'){
          state.notifications[index].title = 'Report shared successfully!';
          state.notifications[index].text = '';
          state.notifications[index].status = 'success'
          state.notifications[index].loading = false;
          state.notifications[index].active = true;
        } else {
          state.notifications[index].title = 'An error occurred!';
          state.notifications[index].text = '';
          state.notifications[index].status = 'error'
          state.notifications[index].loading = false;
          state.notifications[index].active = true;
        }
      },
      startDocumentDownloadDownloadCenter(state, payload){

        let found = state.notifications.findIndex(n => n.id === payload.id);
        let n = {
          id: payload.id,
          type: 'document_download',
          icon: 'mdi-file',
          title: 'Generating Document',
          dismissible: true,
          status: 'open',
          loading: true,
          text: 'You will be able to begin the download shortly. Once complete, you can access it via the Download Center',
          action: false,
          btn_text: false,
          active: true
        }

        if(found >= 0){
          Vue.set(state.notifications, found,n);
        } else {
          state.notifications.push(n);
        }

      },

      updateDocumentDownload(state, payload){
			  let index = state.notifications.findIndex(n => n.id === payload.id);

			  if(payload.success){
          state.notifications[index].title = 'Your download is ready!';
          state.notifications[index].text = '';
          state.notifications[index].status = 'success';
          state.notifications[index].loading = false;
          state.notifications[index].btn_text = 'Download Report';
          state.notifications[index].action = 'download_file';
          state.notifications[index].file = payload.data;
          state.notifications[index].content_type = payload.content_type;
          state.notifications[index].filename = payload.filename;
          state.notifications[index].active = true;
        } else {
          state.notifications[index].title = 'An error occurred';
          state.notifications[index].text = payload.message;
          state.notifications[index].status = 'error';
          state.notifications[index].loading = false;
          state.notifications[index].active = true;
        }
      },
      addIncomingCall(state, payload){

        let found = state.notifications.findIndex(n => n.id === payload.id);

        let n = {
          id: 'incoming_call' + payload.id,
          icon: 'mdi-phone',
          type: 'incoming_call',
          title: 'Incoming Call',
          dismissible: true,
          status: 'open',
          loading: false,
          text: 'Incoming call from lots of people',
          action: false,
          btn_text: false,
          active: true
        }

        if(found >= 0){
          Vue.set(state.notifications, found,n);
        } else {
          state.notifications.push(n);
        }


      },
      



      updateIncomingCall(state, payload){
      },
      addIncomingText(state, payload){

      },
      addIncomingEmail(state, payload){
        let found = state.notifications.findIndex(n => n.id === payload.id);
        let title = (payload?.contact?.first && payload?.contact?.last) ? `${payload.contact.first} ${payload.contact.last}` : 'Incoming Email';
        let n = {
          id: 'new_email_' + payload.email_id,
          icon: 'mdi-email-send-outline',
          type: 'message_center',
          title: title,
          time: `${payload.time}`,
          dismissible: true,
          status: 'open',
          loading: false,
          text: `${payload.message}`,
          subject: `${payload.subject}`,
          action: false,
          btn_text:false,
          active: true,
          contact_id: payload.contact_id
        }

        if(found >= 0){
          Vue.set(state.notifications, found,n);
        } else {
          state.notifications.push(n);
        }

      },
      addIncomingSMS(state, payload){
        let found = state.notifications.findIndex(n => n.id === payload.id);
        
        let title = (payload?.contact?.first && payload?.contact?.last) ? `${payload.contact.first} ${payload.contact.last}` : 'Incoming SMS';

        let n = {
          id: 'new_sms_' + payload.interaction_id,
          icon: 'mdi-message-arrow-right-outline',
          type: 'message_center',
          title: title,
          time: `${payload.time}`,
          dismissible: true,
          status: 'open',
          loading: false,
          btn_text: false,
          action: false,
          text: `${payload.message}`,
          active: true,
          contact_id: payload.contact_id
        }

        if(found >= 0){
          Vue.set(state.notifications, found, n);
        } else {
          state.notifications.push(n);
        }
      }, 
      newLease(state, payload){
        
        let found = state.notifications.findIndex(n => n.id === payload.id);
    
        let n = {
          id:  'new_lease_' + payload.lease_id,
          icon: 'mdi-plus',
          type: 'link',
          title: 'New Lease!',
          dismissible: true,
          status: 'success',
          loading: false,
          text: `${payload.first} ${payload.last} has rented unit #${payload.unit_number}!`,
          action: `/contacts/${payload.contact_id}`,
          btn_text: "Go to Profile",
          active: true
        }

        if(found >= 0){
          Vue.set(state.notifications, found,n);
        } else {
          state.notifications.push(n);
        }
      },
      newReservation(state, payload){
        
        let found = state.notifications.findIndex(n => n.id === payload.id);
    
        let n = {
          id: 'new_reservation_' + payload.lease_id,
          icon: 'mdi-plus',
          type: 'show_reservation',
          title: 'New Reservation!',
          dismissible: true,
          status: 'success',
          loading: false,
          text: `${payload.first} ${payload.last} has reserved unit #${payload.unit_number}!`,
          action: `/contacts/${payload.contact_id}`,
          btn_text: "Go to Rreservation",
          active: true,
          payload: payload
        }

        if(found >= 0){
          Vue.set(state.notifications, found,n);
        } else {
          state.notifications.push(n);
        }
      },
      newLead(state, payload){
        
        let found = state.notifications.findIndex(n => n.id === payload.id);
    
        let n = {
          id:  'new_lead_' + payload.lead_id,
          icon: 'mdi-plus',
          type: 'link',
          title: 'New Lead!',
          dismissible: true,
          status: 'success',
          loading: false,
          text: `${payload.first} ${payload.last} has submitted a new inquiry!`,
          action: `/leads`,
          btn_text: "Go to Leads",
          active: true
        }

        if(found >= 0){
          Vue.set(state.notifications, found,n);
        } else {
          state.notifications.push(n);
        }
      },

      //Code added by BCT team
      setAlertMessage(state, payload) {
        let found = state.notifications.findIndex(n => n.id === payload.id);
        if(found >= 0){
          state.notifications[found].text = payload.text;
          state.notifications[found].errors = payload.form_errors;
          state.notifications[found].active = true;
        } else {
          state.notifications.push({
            id: payload.id,
            type: payload.property_status,
            icon: payload.icon,
            title: payload.title,
            dismissible: true,
            status: payload.status,
            loading: payload.loading || false,
            text: payload.text,
            validationText: payload.validationText,
            errors: payload.form_errors,
            action: payload.action || false,
            subtext: payload.subtext,
            btn_text: payload.btn_text || false,
            active: true
          })
        }
      },

      startProcessRentChanges(state, payload){
        let found = state.notifications.findIndex(n => n.id === payload.id);
        let n = {
          id: payload.id,
          type: 'document_download',
          icon: 'mdi-file',
          title: payload.message.title,
          dismissible: true,
          status: 'open',
          loading: true,
          text: payload.message.text,
          action: false,
          btn_text: false,
          active: true
        }

        if(found >= 0){
          Vue.set(state.notifications, found,n);
        } else {
          state.notifications.push(n);
        }

      }
		};
	}
}

export default Notifications;
