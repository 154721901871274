export default {
	LEASES: 'companies/:company_id/leases/',  // save is same as lease, just post
	FIRST_INVOICE: 'companies/:company_id/leases/get-first-invoice/',
	PAYMENT_METHOD_OPTIONS: 'companies/:company_id/leases/get-payment-method-options/',
	ADD_TENANT_TO_LEASE: 'companies/:company_id/leases/add-tenant/',
	GET_ACTIVITY: 'companies/:company_id/leases/get-activity/',
	GET_DUE_INVOICES: 'companies/:company_id/leases/get-due-invoices/',
	REMOVE_TENANT: 'companies/:company_id/leases/remove-tenant/',
	// GET_TENANTS: 'leases/get-tenants/',
	DELETE_LEASE: 'companies/:company_id/leases/delete/',
	GET_LEASE_TEMPLATE: 'companies/:company_id/leases/get-template/',
	LEASE_CHECKLIST: 'companies/:company_id/leases/checklist/',
	LEASE_CHECKLIST_ITEMS: 'companies/:company_id/leases/checklist-items/',
	FINALIZE_LEASE: 'companies/:company_id/leases/finalize-lease/',
	LEASE_CHECKLIST_ITEM: 'companies/:company_id/leases/checklist-item/',
	LEASE_OMNI: 'companies/:company_id/leases/omni-search/'
}
