import api from "../../assets/api.js";

class TenantProfile {
  constructor() {
    this.namespaced = true;

    this.state = {
      leases: {
				current: [],
				closed: []
			},
			error: null,
      refreshTenantProfile: false,
    };

    this.getters = {
			getError: (state) => state.error,
      getRefreshTenantProfile: (state) => state.refreshTenantProfile,
			getLease: (state) => (payload) => {
				const { lease_id, lease_status } = payload;
				const lease = state.leases[lease_status].find(l => l.id == lease_id);
        return lease;
			},
      getShouldEmailMonthlyInvoice: (state) => (payload) => {
        const { lease_id, lease_status } = payload;
				const lease = state.leases[lease_status].find(l => l.id == lease_id);
        return lease?.send_invoice;
      },
    };

    this.mutations = {
			setLeases: (state, payload) => {
				const { leases, leases_status } = payload;
				state.leases[leases_status] = leases;
			},
      setShouldEmailMonthlyInvoice: (state, payload) => {
        const { lease_id, should_email_monthly_invoice, lease_status } = payload;
				const updatedLeaseIndex = state.leases[lease_status].findIndex(l => l.id == lease_id);
        state.leases[lease_status][updatedLeaseIndex].send_invoice = should_email_monthly_invoice;
      },
			setError: (state, payload) => {
				const { message } = payload;
				state.error = message;
			},
      setRefreshTenantProfile: (state, trueOrFalse) => state.refreshTenantProfile = trueOrFalse
    };

    this.actions = {
      async updateShouldEmailMonthlyInvoice({ commit, state, dispatch }, payload) {
				const { lease_id, should_email_monthly_invoice, lease_status } = payload;

        try {
          commit('setError', { message: '' });
					await api.put(this, `${api.LEASES}${lease_id}/send-invoice`, {
            send_invoice: should_email_monthly_invoice,
          });
	        commit('setShouldEmailMonthlyInvoice', {
						lease_id, should_email_monthly_invoice, lease_status
					});
        } catch (err) {
					commit('setError', { message: err });
        } 
      } 
    };
  }
}

export default TenantProfile;
