export default {
	DASHBOARD: 'companies/:company_id/dashboard/',
	DASHBOARD_ACTION_ITEMS: 'companies/:company_id/dashboard/action-items/',
	DASHBOARD_AGING: 'companies/:company_id/dashboard/aging/',
	DASHBOARD_MAINTENANCE_SUMMARY: 'companies/:company_id/dashboard/maintenance-requests/',
	DASHBOARD_MONTHLY_REVENUE_SUMMARY: 'companies/:company_id/dashboard/revenue-by-month/',
	DASHBOARD_LEADS: 'companies/:company_id/dashboard/leads/',
	DASHBOARD_VACANCIES: 'companies/:company_id/dashboard/vacancy-breakdown/',
	DASHBOARD_VACANCY_BY_CATEGORY: 'companies/:company_id/dashboard/vacancy-by-category/',
	SEND_INVOICE_REVIEW: 'companies/:company_id/invoices/send-review/',
	SEND_INVOICES_TO_TENANTS: 'companies/:company_id/invoices/send-to-tenants/'
}
