export default {
	UNITS: 'companies/:company_id/units/',
	UNITS_QUICK_SAVE: 'companies/:company_id/units/quick-save/',
	UNITS_LEASE: 'companies/:company_id/units/lease/',
	UNITS_IMAGES: 'companies/:company_id/units/images/',
	// UNITS_GET_DETAILS: 'units/get-details/',
	UNITS_FLOORPLAN: 'companies/:company_id/units/floorplan/',
	UNITS_GET_RENTS: 'companies/:company_id/units/rents/',
	UNITS_GET_BEDS: 'companies/:company_id/units/beds/',
	UNITS_GET_BATHS: 'companies/:company_id/units/baths/',
	UNITS_GET_FLOORS: 'companies/:company_id/units/floors/',
	UNITS_GET_BUILDING_TYPES: 'companies/:company_id/units/building-types/',
	UNIT_OPTIONS: 'companies/:company_id/units/options/',
	// LEASE_HISTORY: 'units/get-lease-history/',
	// UNIT_DETAILS: 'units/get-details/',
	UNITS_SEARCH: 'companies/:company_id/units/search/',
	UNITS_GET_TEMPLATE: 'companies/:company_id/units/get-template/',
	UNITS_GET_APPLICATION: 'companies/:company_id/units/get-application/',
	UNITS_UTILITIES: 'companies/:company_id/units/utilities/',
	UNITS_APPLY: 'companies/:company_id/units/apply/',
	AMENITIES: 'companies/:company_id/units/amenities/',
	UNITS_OMNI: 'companies/:company_id/units/omni-search'
}
