import api from '../../assets/api.js';
import Vue from "vue";

const types = {
	SET_DASHBOARDS: 'SET_DASHBOARDS',
	SET_PID: 'SET_PID',
	SET_APIS:'SET_APIS',
	ADD_DASHBOARD: 'ADD_DASHBOARD',
	CLEAR_DASHBOARDS: 'CLEAR_DASHBOARDS',
	SET_DASHBOARD_TYPES: 'SET_DASHBOARD_TYPES',
	SET_UPSELL_DATA: 'SET_UPSELL_DATA',
	SET_UPSELL_LOADER: 'SET_UPSELL_LOADER',
	SET_PROPERTIES: 'SET_PROPERTIES'
}
const headers = {
	'X-storageapi-key': process.env.VUE_APP_GDS_API_KEY,
	'X-storageapi-date': String(Date.now())
}

// TODO: Need to optimize the pagination function
async function getPaginatedData(totalPages, data, cmsEndpoint, appId) {
	let response = [];
	let facilities = []; 
	for (let page = 1; page < totalPages; page += 10) {
	  let promises = [];
	  let limit = page + 10;
	  if (limit > totalPages) {
		limit = totalPages;
	  }
	  for (let pageNo = page + 1; pageNo <= limit; pageNo++) {
		promises.push(Vue.http.get(
			cmsEndpoint + `/v1/owners/${data.owner}/facilities/?page=${pageNo}`, { headers: headers }
		));
	  }
	  response = [...await Promise.all(promises), ...response]
	}
	response?.forEach(facilityResponse => {
		facilities = [...facilities, ...facilityResponse?.body?.applicationData?.[appId]?.[0]?.data ?? []];
	})
	return facilities;
}

class Dashboards {
	constructor(){
		this.namespaced = true;
		this.state = {
			dashboards: [],
			pid: null,
      		dashboard_types: [],
			upsell: {
				loading: true,
				data:[],
			},
			properties: []
		},
		this.getters = {
			dashboards: state => state.dashboards,
			dashboard_types: state => state.dashboard_types,
			get_upsell_data: state => state.upsell.data,
			is_upsell_loading: state => state.upsell.loading,
			owner_properties: state => state.properties
		},
		this.actions = {
			getDashboardTypes: async ({state, commit, dispatch}, payload) => {
				let result = await api.get(this.$app, api.DASHBOARD + 'types');
				commit(types.SET_DASHBOARD_TYPES, result.types);
			},
      setLayout: async ({state, commit, dispatch}, payload) => {
          let result = await api.post(this.$app, api.DASHBOARD, { dashboards: payload });
      },
			getWidget({commit, state, dispatch}, payload){
				// Always pass options with Property ID
				//let options = payload.widget.options ? {...payload.widget.options, property_id: state.pid} : {property_id: state.pid};
				let options = {};

				if(payload.datasource){
					api.get(this.$app, payload.datasource, options).then(board => {
						payload.kpi = board.kpi;
						payload.title = board.title;
						// data needs to be the normalized wrapper
						// Response should always wrap with a data object
						if(payload.type==="chart"){
							payload.data = {
								items: board.items,
								colors: board.colors
							};
						}
	
						if(payload.type==="bar" || payload.type==="linechart" || payload.type==="line"){
	
							payload.data = {
							items: board.items,
							colors: board.colors
							};
						}
	
						// The Vacancy By Category widget is typed as table but needs to be tabs
						if(payload.type==="table" || payload.type==="tabs"){
							payload.data = board.tabs;
							payload.type="tabs";
						}
						commit(types.ADD_DASHBOARD, payload);
					}).catch(e=>{
						console.error('[Store Failure]', e)
					});
				}
			},
			// all widgets pivot off of this property_id
			setPID({ commit }, payload){
				commit(types.SET_PID, payload);
			},
			updateUpsellLoader({ commit }, payload){
				commit(types.SET_UPSELL_LOADER, payload);
			},

			// Get dashboards, then normalize.
			getDashboards({ state, commit, dispatch }, payload) {
				commit(types.CLEAR_DASHBOARDS);
				if(api && api.DASHBOARD){
          //let pid = payload.pid || null;
          //dispatch('setPID', pid);

          // API doesn't require a PID
          api.get(this.$app, api.DASHBOARD).then(dashboards => {
            dashboards.dashboards.forEach(dashboard => {
							dispatch('getWidget', dashboard);
						})
            // dispatch('normalize', dashboard.dashboards.reverse());
          }).catch(e=>{
            console.error('[Store Failure]', e)
          });
				}
			},
			async deleteWidget({ state, commit, dispatch }, payload) {
				await api.delete(this.$app, api.DASHBOARD, payload.widget.id);

				//TODO splice dashboard out of list
        await dispatch('getDashboards', {pid: payload.widget.property_id });

      },
			async addWidget({ state, commit, dispatch}, payload) {
				await api.post(this.$app, api.DASHBOARD, { dashboards: payload.dashboards});
			},
			async fetchUpsellData({state, commit, dispatch, rootState}, payload) {
				let isHBUser = rootState.authenticationStore.isHBUser
				let glossaryAppId = rootState.authenticationStore.glossary_app_id
				let upsellData = ""
				if (
					!isHBUser &&
					!state.upsell.data.length &&
					glossaryAppId
				) {
					try {
						let baseUrl = process.env.VUE_APP_GDS_BASE_URL;
						let GDS_GLOSSARY_ENDPOINT = baseUrl + '/v3/applications/' + glossaryAppId;

						let response = await Vue.http.get(
								GDS_GLOSSARY_ENDPOINT + '/api/contents/upsell/', { headers: headers }
						); 
						upsellData = response?.body?.applicationData?.[glossaryAppId]?.[0] ?? [];
					} catch(error) {
						console.error('Fetch Upsell Data', error);
					}
					
				}
				commit(types.SET_UPSELL_LOADER, false);
				await dispatch('fetchOwnerFacilityData', upsellData);
			},

			async fetchOwnerFacilityData({state, commit, dispatch, rootState}, payload) {
				let cmsAppId = rootState?.authenticationStore?.cms_app_id ?? ''
				let ownerId = rootState.authenticationStore?.company?.gds_owner_id ?? ''
				let nonHbProperties =  rootState.propertiesStore?.nonHBProperties
				let queryParams = `&facility_id=${nonHbProperties.join('&facility_id=')}`
				let facilities = []
				if(cmsAppId && ownerId) {
					try {
						let baseUrl = process.env.VUE_APP_GDS_BASE_URL;
						let GDS_CMS_ENDPOINT = baseUrl + '/v3/applications/' + cmsAppId;
						let page = 1;
	
						let response = await Vue.http.get(
							GDS_CMS_ENDPOINT + `/v1/owners/${ownerId}/facilities/?page=${page}`, { headers: headers }
						);
						let responseData = response?.body?.applicationData?.[cmsAppId]?.[0];
						facilities = responseData?.data ?? [];

						let totalPages = responseData?.pagination?.total_pages;
						let paginationQuery = {
							owner: ownerId,
							query_params: queryParams

						} 
						let paginatedResponse = await getPaginatedData(totalPages, paginationQuery, GDS_CMS_ENDPOINT, cmsAppId)
						facilities = [...facilities, ...paginatedResponse]

						// TODO: Need optimize and move in to utils.js
						let uniqueFacilities = [];
						let uniqueFacilityList = facilities.filter(data => {
						  let isDuplicate = uniqueFacilities.includes(data.id);
						
						  if (!isDuplicate) {
							uniqueFacilities.push(data.id);
							return true;
						  }
						  return false;
						});

	
						let showTiCard = true;
						let showDsCard = true;

						for (let facility = 0; facility < uniqueFacilityList.length; facility++ ) {
							if(uniqueFacilityList[facility].has_ti) showTiCard = false;
							if(uniqueFacilityList[facility].has_ds) showDsCard = false;
							if(!showTiCard && !showDsCard) break;
						};

						let upsellDataCopy = JSON.parse(JSON.stringify(payload));
						if(!showTiCard) {
							upsellDataCopy.dashboard = upsellDataCopy?.dashboard?.filter(data => data.upsell_type != 'tenant_interface');
						}
						if(!showDsCard) {	
							upsellDataCopy.dashboard = upsellDataCopy?.dashboard?.filter(data => data.upsell_type != 'digital_signage');
						}
						commit(types.SET_UPSELL_DATA, upsellDataCopy);
	
						commit(types.SET_PROPERTIES, uniqueFacilityList);
					} catch(error) {
						console.error('Fetch Upsell Data', error);
						commit(types.SET_UPSELL_DATA, payload);
					}
				}
			}
		},
		this.mutations = {
			[types.SET_DASHBOARDS](state, payload){
				state.dashboards = payload;
			},
			[types.SET_PID](state, payload){
				state.pid = payload;
			},
			[types.ADD_DASHBOARD](state, payload) {
				state.dashboards.push(payload);
			},
			[types.SET_DASHBOARD_TYPES](state, payload) {
				state.dashboard_types = payload;
			},
			[types.CLEAR_DASHBOARDS](state, payload){
				state.dashboards = [];
			},
			[types.SET_APIS](state, payload){
				payload.widget.apis = payload.apis;
			},
			[types.SET_UPSELL_DATA](state, payload){
				state.upsell.data = payload;
			},
			[types.SET_UPSELL_LOADER](state, payload){
				state.upsell.loading = payload;
			},
			[types.SET_PROPERTIES](state, payload){
				state.properties = payload;
			},
		}
	}
}
export default Dashboards;
