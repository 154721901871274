import moment from "moment";
import timeDifferenceFn from '../time_diff';

export default {
  initilizeCharmCallState(state) {
    state.isCharmCallToastOpen = false;
    state.notification = {};
    state.incomingNotifications = [];
    state.twilioCallDetails = [];
    state.type = "mini-charm";
    state.view = "default";
    state.outgoingPhoneNumber = '';
    state.searchedContact = {};
    state.searchedContactDetails = {};
    state.callWrapData = '';
    state.isNotificationActive = false;
  },

  setCharmCallToastState(state, value) {
    if (value && Object.keys(state.notification).length) {
      state.isCharmCallToastOpen = true
    } else {
      state.isCharmCallToastOpen = false;
    }
  },
  setCharmNotification(state, payload) {
    state.notification = {};
    payload.communication_time = currentTime();
    payload.call_duration_time = '';
    state.notification = { ...payload };
    state.isNotificationActive = true;
  },

  setIncomingNotification(state, payload) {
    payload.communication_time = currentTime();
    state.incomingNotifications.push(payload);
  },

  setIncomingCallToast(state, payload) {
    payload = {...payload.callerDetails, twilioDetails: payload.twilioDetails.data};
    payload.communication_time = currentTime();
    payload.call_duration_time = '';
    if (!state.isNotificationActive || state.notification?.status === 'call in') {
      state.notification = {};
      state.notification = { ...payload };
      state.isNotificationActive = true;
      state.isCharmCallToastOpen = true;
    } else {
      state.incomingNotifications = [...state.incomingNotifications.filter(f => f.status !== 'call in')]
      state.incomingNotifications.push(payload);
    }
  },

  checkIfAlreadyOnAnotherCall(state, event) {
    if (state.incomingNotifications?.length && state.incomingNotifications.some(f => f.status === 'active')) {
      let foundIndex = state.incomingNotifications.findIndex(f => f.status === 'active');
      if (foundIndex !== -1) {
        state.activeCallId = state.incomingNotifications[foundIndex].id;
        state.activeCallContactName = state.incomingNotifications[foundIndex].contact_unknown ? 'Unknown' : state.incomingNotifications[foundIndex].contact_name;
      }
      if (event == "answer-call-button-clicked") {
        state.confirmation = "answer";
        state.notification.time = currentTime();
        state.notification = { ...state.notification };
      } else if (event == "take-off-hold-button-clicked") {
        state.confirmation = "take off hold";
      } else if (event == "call-back-button-clicked") {
        state.confirmation = "call back";
      }
    } else {      
      state.confirmation = "";
    }
  },
  
  setEndCall(state, from) {
    if (from === ('+' + state.notification.phone_number) && ['call in', 'call out'].includes(state.notification.communication_type)) {
      state.notification.status = "ended";
      // Dummy url: This is only for visiblity of call recording button
      if (state.notification.communication_type === 'call in') {
        state.notification.playback_url = 'https://api.twilio.com';
      }
      state.notification = {...state.notification};
      state.twilioCallDetails = state.twilioCallDetails.filter(f => f.id !== state.notification.id);
    } else if (state.incomingNotifications.length) {
      let foundIndex = state.incomingNotifications.findIndex( item => (('+' + item.phone_number) === from) && ['call in', 'call out'].includes(item.communication_type));
      if (foundIndex !== -1) {
        state.incomingNotifications[foundIndex].status = 'ended';
        // Dummy url: This is only for visiblity of call recording button
        if (state.incomingNotifications[foundIndex].communication_type === 'call in') {
          state.incomingNotifications[foundIndex].playback_url = 'https://api.twilio.com';
        }
        state.twilioCallDetails = state.twilioCallDetails.filter(f => f.id !== state.incomingNotifications[foundIndex].id);
      }
    }
    state.activeCallId = "";
    state.activeCallContactName = "";
  },

  removeTwilioDetails(state, payload) {
    state.twilioCallDetails = state.twilioCallDetails.filter(f => f.id !== payload.id);
  },
 
  updateIvrPaymentEnable(state,payload){
    state.IvrPaymentEnable = payload 
  },
  ivrPaymentType(state,payload){
    state.ivrPaymentType = payload
  },

  updateIvrPaymentMethod(state,payload){
    state.ivrPaymentMethod = payload
  },  
  enableIvrProcessPayment(state,enabled = false){
    state.enableIvrProcessPayment = enabled
    },
  paymentConnectorResponse(state,payload){
      state.paymentConnectorResponse = payload
      },
  ivrPaymentButtonLoading(state,loading = false){   
    state.ivrPaymentButtonLoading = loading
        },
   ivrTimer(state,time = ''){
          state.ivrTimer = time
        },
  ivrPaymentSessionStarted(state,enabled = false){
          state.ivrPaymentSessionStarted = enabled
        },
 
  setHandleDismiss(state, payload) {
    if (payload && payload.id) {
      if (state.incomingNotifications.length) {
        state.incomingNotifications = [...state.incomingNotifications.filter(el => el.id !== payload.id)];
      }
      if (state.notification.id == payload.id) {
        state.notification = {};
        if (state.incomingNotifications.length) {
          state.notification = { ...state.incomingNotifications.pop() };
          state.isNotificationActive = true;
        } else {
          state.isCharmCallToastOpen = false;
          state.isNotificationActive = false;
        }
      }
    }
  },
  setHandleDismissIncomingCall(state, payload) {
    if (payload && payload.id) {
      if (state.incomingNotifications.length) {

        state.incomingNotifications = [...state.incomingNotifications.filter(el => !(el.id == payload.id && el.status == 'call in'))];
      }
      if (state.notification.id == payload.id && state.notification.status == 'call in') {
        state.notification = {};
        if (state.incomingNotifications.length) {
          state.notification = { ...state.incomingNotifications.pop() };
          state.isNotificationActive = true;
        } else {
          state.isCharmCallToastOpen = false;
          state.isNotificationActive = false;
        }
      }
    }
  },
  setHandleDismissIncomingCall2(state, payload) {
    let caller = '+' + state.notification.phone_number
    if (payload?.parameters?.From === caller) {
      if (state.incomingNotifications.length) {                                  
        state.incomingNotifications = [...state.incomingNotifications.filter(el => !(`+${el.phone_number}` == payload?.parameters?.From && el.status == 'call in'))];
      }
      if ( state.notification.status == 'call in') {
        state.notification = {};
        if (state.incomingNotifications.length) {
          state.notification = { ...state.incomingNotifications.pop() };
          state.isNotificationActive = true;
        } else {
          state.isCharmCallToastOpen = false;
          state.isNotificationActive = false;
        }
      }
    }
   
  },
  setToastItemClicked(state, payload) {
    // reset confirmation prop to make sure it's not showing on the notification that swaps.
    state.confirmation = "";
    let currentNotification = state.notification;
    state.incomingNotifications.splice(payload.i, 1);
    state.incomingNotifications.push(currentNotification);
    state.notification = payload.notification;
    state.notification.status = payload.notification.status;
  },
  resetDurationTimer(state) {
    state.notification.call_duration_time = "00:00";
  },
  setHandleClose(state) {
    if (state.incomingNotifications.length) {
      state.notification = {};
      state.notification = state.incomingNotifications[state.incomingNotifications.length - 1];
      state.incomingNotifications.pop();
      state.isNotificationActive = true;
    } else {
      state.notification = {};
      state.isCharmCallToastOpen = false;
      state.isNotificationActive = false;
    }
  },

  setStartHoldTimer(state) {
    state.notification.hold_duration_time = timeDifferenceFn.callDurationTime(state.notification.hold_time);
  },
  setResetHoldTimer(state) {
    state.notification.hold_duration_time = '';
  },

  setUpdateNotificationStatus(state, status) {
    state.notification.status = status;
    state.notification = { ...state.notification };
  },
  setNotificationStatusHold(state) {
    if (state.notification.status === 'active') {
      state.notification.status = 'hold';
      state.notification.hold_time = currentTime();
      state.notification = { ...state.notification };
    } else {
      let foundIndex = state.incomingNotifications.findIndex(f => f.status === 'active');
      if (foundIndex !== -1) {
        state.incomingNotifications[foundIndex].status = 'hold';
        state.incomingNotifications[foundIndex].hold_time = currentTime();
      }
    }
  },

  setNotificationStatusActive(state) {
    if (state.notification.status === 'hold') {
      state.notification.status = 'active';
      state.notification.hold_time = '';
      state.notification = { ...state.notification };
    } else {
      let foundIndex = state.incomingNotifications.findIndex(f => f.status === 'hold');
      if (foundIndex !== -1) {
        state.incomingNotifications[foundIndex].status = 'active';
        state.incomingNotifications[foundIndex].hold_time = '';
      }
    }
  },

  setType(state, payload) {
    state.type = payload;
  },
  setView(state, payload) {
    state.view = payload;
  },
  setTitle(state, payload) {
    state.title = payload;
  },
  setCommunicationType(state, payload) {
    state.notification.communication_type = payload;
  },
  setEmptyNotification(state) {
    state.incomingNotifications = [];
  },

  // Every sec
  setCallBackTimer(state) {
    if (["reservation", "missed", "voicemail"].includes(state.notification.status)) {
      state.notification.call_back_time = timeDifferenceFn.callBackTime(state.notification.time);
    } else {
      state.notification.call_back_time = "";
    }
  },

  setIncomingCallToMissedCall(state, payload) {
    if (payload.id === state.notification.id && state.notification.status === "call in") {
      state.notification.status = payload.status;
      state.notification.communication_type = payload.comm_type;
      state.notification.time = currentTime();
      state.notification.call_back_time = '00:01';
      state.notification = { ...state.notification };
    } else if (state.incomingNotifications.length) {
      let foundIndex = state.incomingNotifications.findIndex(item => payload.id === item.id && item.status === "call in");
      if (foundIndex !== -1) {
        state.incomingNotifications[foundIndex].status = payload.status;
        state.incomingNotifications[foundIndex].communication_type = payload.comm_type;
        state.incomingNotifications[foundIndex].time = currentTime();
        state.incomingNotifications[foundIndex].call_back_time = '00:01';
      }
    }
  },

  setTwilioCallDetails(state, payload) {
    let idx = null;
    if (state.twilioCallDetails.length) {
      idx = state.twilioCallDetails.findIndex(item => item.id === payload.id);
      if (idx >= 0) {
        state.twilioCallDetails[idx].data = payload.data;
      }
    }
    if (!state.twilioCallDetails.length || (idx === -1)) {
      state.twilioCallDetails.push(payload);
    }
  },

  setStartCallDurationTime(state) {
    if (['active', 'hold'].includes(state.notification.status)) {
      state.notification.call_duration_time = timeDifferenceFn.callDurationTime(state.notification.time);
    }

  },
  commitSelectedContact(state, payload) {
    if (payload && payload.id) {
      state.searchedContact = {};
      state.searchedContact = { ...payload };
      state.notification.selected_contact_id = payload.id;
      state.notification.selected_contact_name = `${payload.first} ${payload.last}`;
      state.notification.selected_contact_status = payload.status;
      state.notification.selected_contact_phone = `${payload.Phones && payload.Phones.length && payload.Phones[0].phone}` || 'NA';
      state.notification.selected_contact_email = payload.email;
      state.notification.selected_contact_address = payload.contact_address;
      state.notification.selected_contact_access_codes = payload.Access;
      state.notification.selected_contact_units = payload.contactUnits;
      state.notification = { ...state.notification };
    }
  },
  cancelSelectedContact(state) {
    state.notification.selected_contact_id = null;
    state.notification.selected_contact_name = null;
    state.notification.selected_contact_status = null;
    state.notification.selected_contact_phone = null;
    state.notification.selected_contact_email = null;
    state.notification.selected_contact_address = null;
    state.notification.selected_contact_access_codes = null;
    state.notification.selected_contact_units = null;
    state.notification.contact_added_to_selected_contact = false;
    state.notification.phone_number_added_to_selected_contact = false;
    state.notification = { ...state.notification };
    state.searchedContact = {};
    state.searchedContactDetails = {};
  },

  setCallCompleted(state, data) {
    if (data.id === state.notification.id) {
      state.notification.status = "ended";
    } else if (state.incomingNotifications.length) {
      let foundIndex = state.incomingNotifications.findIndex(item => (data.id === item.id));
      if (foundIndex !== -1) {
        state.incomingNotifications[foundIndex].status = "ended";
      }
    }
  },

  setCallRecordingUrl(state, data) {
    if (data.id === state.notification.id) {
      state.notification.playback_url = data.playback_url;
    } else if (state.incomingNotifications.length) {
      let foundIndex = state.incomingNotifications.findIndex(item => (data.id === item.id));
      if (foundIndex !== -1) {
        state.incomingNotifications[foundIndex].playback_url = data.playback_url;
      }
    }
  },

  setOutgoingCall(state) {
    state.outgoingPhoneNumber = '+' + (state.notification.phone_number || state.notification.contact_phone);
    state.title = 'Calling...';
    state.notification.id = state.outgoingPhoneNumber;
    state.notification.status = 'call out';
    state.notification.communication_type = 'call out';
    state.notification.communication_time = currentTime();
    state.notification.playback_url = '';
  },

  setAnswerCall(state) {
    state.activeCallId = state.notification.id;
    state.activeCallContactName = state.notification.contact_unknown ? "Unknown" : state.notification.contact_name;
    state.notification.status = "active";
    state.notification.time = currentTime();
    state.notification.call_duration_time = '00:01';
    state.notification = { ...state.notification };
  },
  setupdateConfirmation(state, payload) {
    state.confirmation = payload;
  },

  setNotificationInteraction(state) {
    if (['reservation', 'voicemail', 'missed'].includes(state.notification.communication_type)) {
      state.notification.is_interacted = true;
      state.notification = { ...state.notification };
    }
  },

  setAdditionalContact(state, contact) {
    state.searchedContactDetails?.alternate_contacts?.push(contact);
    state.notification.contact_added_to_selected_contact = true;
    state.notification = { ...state.notification };
  },

  setAdditionalPhone(state, contact_id) {
    state.searchedContactDetails?.alternate_phones?.push(contact_id);
    state.notification.phone_number_added_to_selected_contact = true;
    state.notification = { ...state.notification };
  },
  setDetailsOfSearchedContact(state, data) {
    state.searchedContactDetails = data;
  },
  setContactUnits(state, data) {
    state.notification.contact_units = [];
    state.notification.contact_units = data.contact_units;
  },
  callWrapData(state, data) {
    state.callWrapData = data;
  },

  setLoadingAction(state, value) {
    if (value.eventStatus === state.notification.loading && value.status === false) {
      state.notification.loading = false;
    } else if (!value.status){
      state.incomingNotifications.map((el, index) => {
        if(el.loading === value.eventStatus){
          state.incomingNotifications[index].loading = false;
        }
      })
    }else if (value.status) {
      state.notification.loading = value.eventStatus;
      state.notification = { ...state.notification }
    }
  },

  stopLoadingAnimation(state) {
    state.notification.loading = false;
    state.incomingNotifications?.forEach(e => e.loading = false);
  },

  setMissedCallToVoicemail(state, data) {
    data.time = currentTime();
    if (data.id === state.notification.id && state.notification.status === "missed") {
      state.notification = { ...data };
    } else if (state.incomingNotifications.length) {
      let foundIndex = state.incomingNotifications.findIndex(item => data.id === item.id && item.status === "missed");
      if (foundIndex !== -1) {
        state.incomingNotifications[foundIndex] = {...data};
      }
    }
  }

};

function currentTime() {
  return moment().utc().local().format('YYYY-MM-DD HH:mm:ss');
}
