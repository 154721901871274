
/**
 * function to  generate the property rent managemant API URL
 * @param {String} property_id - hashed id of selected property
 * @returns {String} - generated URL with property id.
*/
const getPropertyRentManagementUrl = (property_id) => `companies/:company_id/properties/${property_id}/rent-management/`


/**
 * function to  generate the property rent managemant plans API URL
 * @param {String} property_id - hashed id of selected property
 * @returns {String} - generated URL with property id and space group id.
*/
const getPropertySpaceGroupRentPlansUrl = (property_id, space_group_hash) => `${getPropertyRentManagementUrl(property_id)}space-groups/${space_group_hash}/`

/**
 * function to  generate rent managemant plans list API URL
*/
const getCompanyRentPlansUrl = () => 'companies/:company_id/rent-management/plans/'


export default {
	getPropertyRentManagementUrl,
	getPropertySpaceGroupRentPlansUrl,
	getCompanyRentPlansUrl,
	COMPANY_RENT_MANAGEMENT: 'companies/:company_id/rent-management/'
}
