import api from '../../../assets/api.js';

const types = {
    SET_CORPORATE_PRODUCTS: 'SET_CORPORATE_PRODUCTS',
    SET_MERCHANDISE_TOGGLE: 'SET_MERCHANDISE_TOGGLE'
};

class Products {
    constructor() {
        this.namespaced = true;
        this.state = {
            corporateProducts: [],
            propertyProducts: [],
            merchandiseToggle: false
        };
        this.getters = {
            corporateProducts: state => state.corporateProducts,
            propertyProducts: state => state.propertyProducts,
            merchandiseSettings: state => state.merchandiseToggle
        };
        this.actions = {
            corporateProductsAction({ commit }) {
                api.get(this.$app, api.MERCHANDISE).then(payload => {
                    commit(types.SET_TODOS, payload.map(t => {
                        t.is_dismissed = 0;
                        t.is_snoozed = 0;
                        return t;
                    }));
                });
            },
            async getMerchandiseSettingsAction({ commit }) {
                let response = await api.get(this.$app, api.SETTINGS + '?category=merchandiseManagement');
                let isEnabled = response.settings.find(x=>x.name === 'allowMerchandiseManagement');
                commit(types.SET_MERCHANDISE_TOGGLE, isEnabled && (isEnabled.value === 1 || isEnabled.value === '1' ) ? true : false);
            },
            async setMerchandiseSettingsAction({ commit, dispatch }, payload) {
                await api.post(this.$app, api.SETTINGS +'billing', payload);
                dispatch('getMerchandiseSettingsAction')
            }
        };
        this.mutations = {
            [types.SET_CORPORATE_PRODUCTS](state, payload) {
                state.corporateProducts = payload;
            },
            [types.SET_MERCHANDISE_TOGGLE](state, payload) {
                state.merchandiseToggle = payload;
            }
        }
    }
}

export default Products;
