import api from '../../assets/api.js';
import Vue from "vue";

const types = {
    SET_MANUAL_RATE_CHANGE: 'SET_RATE_CHANGE_SCHEDULED',
    SET_AUTO_RATE_CHANGE: 'SET_AUTO_RATE_CHANGE'
};

class RateManagement {
    constructor(){
        this.namespaced = true;
        this.state = {
            manualRateChange: [],
            autoRateChange: []
        };
        this.getters = {
            manualRateChange: state => state.manualRateChange,
            autoRateChange: state => state.autoRateChange
        };
        this.mutations = {
            [types.SET_MANUAL_RATE_CHANGE](state, payload) {
				state.manualRateChange = payload;
            },
            [types.SET_AUTO_RATE_CHANGE](state, payload) {
				state.autoRateChange = payload;
            }
        };
        this.actions = {
            async getRateChanges({commit}, data = null){
                await api.get( this.$app, api.RATE_MANAGEMENT + data.path).then(payload => {
                    commit(types.SET_MANUAL_RATE_CHANGE, payload.rate_changes);
                });
            },
            async rateChangeSkipCancel({commit, dispatch}, data = null){
                if(data.type == "skipped") {
                    await api.put( this.$app, api.RATE_MANAGEMENT + 'rate-changes/' + data.path, null, data.context).then(payload => {
                        dispatch('getRateChanges', {component: data.component, path: 'rate-changes?type=future&limit=10&offset=0'});
                    });
                } else {
                    await api.delete( this.$app, api.RATE_MANAGEMENT + 'rate-changes/' + data.id).then(payload => {
                        dispatch('getRateChanges', {component: data.component, path: 'rate-changes?type=future&limit=10&offset=0'});
                    });
                }
            },
            async getAutoRateChange({commit}, data = null){
                await api.get( this.$app, api.RATE_MANAGEMENT + 'rate-change-configurations?type=auto').then(payload => {
                    commit(types.SET_AUTO_RATE_CHANGE, payload.rate_change_configurations);
                });
            },
            async getReviewedList({commit}, data = null){
                await api.get(this.$app, api.RATE_MANAGEMENT + data.config_id).then(payload => {
                    console.log('payload :>> ', payload);
                });
            },
            async rateChangesReviewApprove({commit, dispatch}, data = null){
                let leasesData = {};
                leasesData.leases = data.leases;
                leasesData.skipped = data.skipped;
                await api.put( this.$app, api.RATE_MANAGEMENT + data.path, leasesData).then(r => {
                    dispatch('getRateChanges', {component: this, path: 'rate-changes?type=future&limit=10&offset=0'});
                    if (data.type == 'reviewed' && !data.skipped) commit('notificationsStore/startDocumentDownload', {id: r.document_id}, {root:true});
                });
            },
            async addRateChange({ commit, dispatch }, data = null) {
                if(data.type == "auto") {
                    await api.post(this.$app, api.RATE_MANAGEMENT + 'rate-change-configurations/', data).then(r => {
                        dispatch('getAutoRateChange', {component: this});
                    });
                } else {
                    await api.post(this.$app, api.RATE_MANAGEMENT + 'rate-changes/', data).then(r => {
                        dispatch('getRateChanges', {component: this, path: 'rate-changes?type=future&limit=10&offset=0'});
                        if (data.notify_now) commit('notificationsStore/startDocumentDownloadDownloadCenter', {id: r.document_id}, {root:true});
                    });
                }

            },
            async editRateChange({ dispatch }, data = null) {
                if(data.type == "auto" && !data.editRateChange) {
                    await api.put(this.$app, api.RATE_MANAGEMENT + 'rate-change-configurations/' + data.id, data).then(r => {
                        dispatch('getAutoRateChange', {component: this});
                    });
                } else {
                    await api.put(this.$app, api.RATE_MANAGEMENT + 'rate-changes/' + data.id, data).then(r => {
                        dispatch('getRateChanges', {component: this, path: 'rate-changes?type=future&limit=10&offset=0'});
                    });
                }
            }
        };
    }
}

export default RateManagement;
