import api from '../../assets/api.js';

const types = {
	SET_TODOS: 'SET_TODOS',
	REMOVE_TODO: 'REMOVE_TODO',
	GET_TODOS: 'GET_TODOS'
};

class Todos {
	constructor(){
		this.namespaced = true;
		this.state = {
			todos:[]
		};
		this.getters = {
			todos: state => state.todos
		};
		this.actions = {
			getTodos({ commit }){
				api.get(this.$app, api.TODOS).then(payload => {
            commit(types.SET_TODOS, payload.map(t => {
						t.is_dismissed = 0;
						t.is_snoozed = 0;
						return t;
					}));
                });
			},
			setTodos({commit}, payload) {
				commit(types.SET_TODOS, payload.map(t => {
					t.is_dismissed = 0;
					t.is_snoozed = 0;
					return t;
				}))
			},
			removeTodo({commit, state}, payload){
				var index = state.todos.findIndex(t => {
					return t.id == payload
				});
				let todo = Object.assign({},payload.todo);
				todo.completed = 1;
				api.post(this.$app, api.TODOS, todo).then(r => {
					if(index >= 0){
						commit(types.REMOVE_TODO, index);
					}
				});

			}
		};
		this.mutations = {
			[types.SET_TODOS](state, payload) {
				state.todos = payload;
			},
			[types.REMOVE_TODO](state, payload){
				state.todos.splice(payload, 1);
			}
		}
	}
}

export default Todos;
