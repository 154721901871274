import api from "../../assets/api.js";
class Contact {
  constructor() {
    this.namespaced = true;

    this.state = {
      firstContact: null,
      secondContact: null,
      primaryContactId: null,
      secondaryContactId: null,
      currentProperty: null,
      loading: false,
      isSpaceAccessCodesVendor: false,
      pin: '',
    };

    this.getters = {
      getFirstContact: (state) => state.firstContact,
      getSecondContact: (state) => state.secondContact,
      getPrimaryContactId: (state) => state.primaryContactId,
      getSecondaryContactId: (state) => state.secondaryContactId,
      getCurrentProperty: (state) => state.currentProperty,
      getIsSpaceAccessCodesVendor: (state) => state.isSpaceAccessCodesVendor,
      getPin: (state) => state.pin,
    };

    this.mutations = {
      setFirstContact: (state, payload) => (state.firstContact = payload),
      setSecondContact: (state, payload) => (state.secondContact = payload),
      setPrimaryContactId: (state, payload) => (state.primaryContactId = payload),
      setSecondaryContactId: (state, payload) => (state.secondaryContactId = payload),
      setCurrentProperty: (state, payload) => (state.currentProperty = payload),
      setGateAccessPin: (state, pin) => (state.pin = pin),
      setIsSpaceAccessCodesVendor: (state, isSpaceAccessCodesVendor) => (state.isSpaceAccessCodesVendor = isSpaceAccessCodesVendor),
      resetUnlink(state, payload = {}){
        state.pin = ''
        state.isSpaceAccessCodesVendor = false
      }
    };

    this.actions = {

      async linkContactsOnBackend({ commit, state, dispatch }) {

        const data = {
          primary_contact_id: state.primaryContactId,
          secondary_contact_id: state.secondaryContactId,
          property_id: state.currentProperty
        };

        try {
          const response = await api.post(
            this,
            `${api.CONTACTS}link-contacts`,
            data
          );
        } catch (error) {
          throw error;
        }
      },

      async verifyContactProperties({ commit, state, dispatch }) {

        const data = {
          primary_contact_id: state.primaryContactId,
          secondary_contact_id: state.secondaryContactId,
          property_id: state.currentProperty
        };
        try {
          await api.post(
            this,
            `${api.CONTACTS}link-contacts/verify-properties`,
            data
          );
        } catch (error) {
          throw error;
        }
      },

      async verifyGateAccess({commit, state, dispatch}, payload){
        try {
          if(state.isSpaceAccessCodesVendor){
            await api.post(
              this,
              `${api.PROPERTIES}${payload.lease.Unit.property_id}/validate-space-access-code`,
              {code: state.pin, unit_id: payload.lease.Unit.id}
            );
          } else {
            await api.post(
              this,
              `${api.PROPERTIES}${payload.lease.Unit.property_id}/validate-access-code`,
              {code: state.pin}
            );
          }
        } catch (error) {
          throw error;
        }
      },

      async verifyPayment({commit, state, dispatch}, payload){
        try {
          await api.get(
            this,
            `${api.CONTACTS}${payload.contact_id}/lease/${payload.lease_id}/verify-payment`
          );
        } catch (error) {
          throw error;
        }
      },

      async unLinkSpace({commit, state, dispatch}, payload){
        try {
          let body = {
            pin: state.pin,
            property_id: payload.lease.Unit.property_id,
            lease_id: payload.lease.id
          }

          if(state.isSpaceAccessCodesVendor){
            body.unit_id = payload.lease.Unit.id,
            body.unit_number = payload.lease.Unit.number
          }

          await api.post(
            this,
            `${api.CONTACTS}${payload.contact_id}/unlink`,
            body
          );
        } catch (error) {
          throw error;
        }
      }
    }
  }
}

export default Contact;
