const types = {
	ADD_CALL: 'ADD_CALL',
	ADD_SMS: 'ADD_SMS',
	CLOSE_CALL_WINDOW: 'CLOSE_CALL_WINDOW',
	CLOSE_SMS_WINDOW: 'CLOSE_SMS_WINDOW',
	UPDATE_CALL: 'UPDATE_CALL',
	RESET_SMS_UPDATE: 'RESET_SMS_UPDATE',
	UPDATE_PDF_SUPPORT: 'UPDATE_PDF_SUPPORT'
}

class Calls {
	constructor(){
		this.namespaced = true;
		this.state ={
			calls: [],
			sms: [],
			downloadPdf: false
		},
		this.getters = {
			getCalls: state => state.calls,
			getSMS: state => state.sms,
			getPdfSupport: state => state.downloadPdf
		},
		this.actions = {
			addCall({commit}, payload){
				commit(types.ADD_CALL, payload)
			},
			addSMS({commit}, payload){
				var msg = state.sms.filter(s => s.thread_id === payload.sms_thread_id);

				if(!msg.length){
					commit(types.ADD_SMS, payload)
				} 
			},
			resetSmsUpdate({commit}, payload){
				commit(types.ADD_SMS, payload)
			},
			updateCall({commit}, payload){
				commit(types.UPDATE_CALL, payload)
			},
			closeCallWindow({commit}, payload){
				commit(types.CLOSE_CALL_WINDOW, payload);
			},
			closeSMSWindow({commit}, payload){
				commit(types.CLOSE_SMS_WINDOW, payload);
			},
			setPdfSupport({commit}, payload) {
				commit(types.UPDATE_PDF_SUPPORT, payload)
			}
		},
		this.mutations = {
			[types.ADD_CALL](state, payload){	
				state.calls.push(payload);
			},
			[types.ADD_SMS](state, payload){
				state.sms.push({
					thread_id: payload.sms_thread_id,
					update: false
				});
			},
			[types.RESET_SMS_UPDATE](state, payload){
				state.sms.forEach((msg, i) => {
					if(msg.thread_id !== payload) return;
					state.sms[i].update = false;
				});
			},
			[types.UPDATE_CALL](state, payload){
				state.calls.forEach((call, i) => {
					if(call.callSid !== payload.callSid) return;
					state.calls[i].callStatus = payload.callStatus;
				});
			},
			[types.CLOSE_CALL_WINDOW](state, payload){
				state.calls.splice(payload, 1);
			},
			[types.CLOSE_SMS_WINDOW](state, payload){
				state.SMS.splice(payload, 1);
			},
			[types.UPDATE_PDF_SUPPORT](state, payload){
				state.downloadPdf = payload.downloadPdf
			}
		}
	}
}
export default Calls;