/**
 * function to  generate the property promotion management API URL
 * @param {String} property_id - hashed id of selected property
 * @returns {String} - generated URL with property id.
 */
const getPropertyPromoManagementUrl = (property_id) => `companies/:company_id/properties/${property_id}/promotion-management/`
const updatePromoManagementSpacegroupStatusUrl = (property_id) => `companies/:company_id/properties/${property_id}/promotion-management/space-groups/status`
const getPropertyDiscountManagementUrl = (property_id) => `companies/:company_id/properties/${property_id}/discounts/`;
const syncPropertyPromoManagementUrl = (property_id) => `companies/:company_id/properties/${property_id}/promotion-management/sync`;

export default {
  PROMO_GET_DISCOUNTS: "companies/:company_id/promotions/get-discounts/",
  PROMOTIONS: "companies/:company_id/promotions/",
  PROMOTIONS_SORT: "companies/:company_id/promotions/sort",
  PROMOTION_MANAGEMENT: "companies/:company_id/promotion-management",
  getPropertyPromoManagementUrl,
  updatePromoManagementSpacegroupStatusUrl,
  getPropertyDiscountManagementUrl,
  syncPropertyPromoManagementUrl,
};
