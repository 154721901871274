import api from "../../../assets/api.js";

export default {
paymentConnectorResponse({commit},payload){
  commit('paymentConnectorResponse',payload)
},
ivrPaymentButtonLoading({commit},loading = false){
  commit('ivrPaymentButtonLoading',loading)
},
enableIvrProcessPayment({commit},enabled = false){
  commit('enableIvrProcessPayment',enabled)
  },
ivrTimer({commit},time = ''){
    commit('ivrTimer',time)
  },
ivrPaymentSessionStarted({commit},enabled = false){
    commit('ivrPaymentSessionStarted',enabled)
  },
  showCharmCallToast({ commit, dispatch }, payload) {
    dispatch('globalNotificationsStore/setInSnappedZone', true, {root: true});
    commit("setCharmCallToastState", payload);
  },

  setCharmNotification({ commit }, payload) {
    commit("setCharmNotification", payload);
  },

  addIncomingNotification({ commit } ,payload) {
    commit("setIncomingNotification", payload);
  },
  checkIfAlreadyOnAnotherCall({ commit }, payload) {
    commit("checkIfAlreadyOnAnotherCall", payload);
  },
  endCall({ commit }, payload) {
    commit("setEndCall", payload);
  },
  async handleDismiss({ commit }, payload) {
    commit("cancelSelectedContact");
    await commit("setHandleDismiss", payload);
    return 'dismissed';
  },
  async handleDismissIncomingCall({ commit }, payload) {
    await commit("setHandleDismissIncomingCall", payload);
    return 'dismissed';
  },
  async handleDismissIncomingCall2({ commit }, payload) {
    await commit("setHandleDismissIncomingCall2", payload);
    return 'dismissed';
  },
  toastItemClicked({ commit }, payload) {
    commit("setToastItemClicked", payload);
  },
  resetDurationTimer({ commit }) {
    commit("resetDurationTimer");
  },
  handleClose({ commit }) {
    commit("cancelSelectedContact"); 
    commit("setHandleClose");
  },

  startHoldTimer({ commit }) {
    commit("setStartHoldTimer");
  },
  resetHoldTimer({ commit }) {
    commit("setResetHoldTimer");
  },

  updateNotificationStatus({ commit }, payload) {
    commit("setUpdateNotificationStatus", payload);
  },
  updateType({ commit }, payload) {
    commit("setType", payload);
  },
  updateView({ commit }, payload) {
    commit("setView", payload);
  },
  updateTitle({ commit }, payload) {
    commit("setTitle", payload);
  },
  updateCommunicationType({ commit }, payload) {
    commit("setCommunicationType", payload);
  },
  emptyNotification({ commit }) {
    commit("setEmptyNotification");
  },

  setCallBackTimer({ commit }) {
    commit("setCallBackTimer");
  },

  incomingCallToMissedCall({ commit }, payload) {
    commit('setIncomingCallToMissedCall', payload)
  },

  setTwilioCallDetails({commit}, payload) {
    commit('setTwilioCallDetails', payload)
  },

  startCallDurationTimer({commit}) {
    commit('setStartCallDurationTime')
  },
  commitSelectedContact({commit,dispatch}, payload) {
    dispatch('getDetailsOfSearchedContact',payload);
    commit('commitSelectedContact', payload)
  },
  cancelSelectedContact({commit}) {
    commit('cancelSelectedContact')
  },

  setCallCompleted({ commit}, data) {
    commit('setCallCompleted', data);
  },

  setCallRecordingUrl({ commit}, data) {
    commit('setCallRecordingUrl', data);
  },

  setOutgoingCallData({commit}, payload) {
    commit('setOutgoingCall', payload);
  },
  async addAdditionalContact({commit, dispatch}, payload) {
    let data = { related_contact_id: payload.caller.contact_id }
    let notify = {msg: '', type: ''};
    await api.put(this, `${api.CHARM_CONTACT}${payload.searchedContact.id}/additional-contact`, data).then(r => {
      if (payload.layout === 'charmCall') {commit('setAdditionalContact', {id: payload.caller.contact_id});}
      if (payload.layout === 'charmNotification') {dispatch('charmNotificationStore/addAdditionalContact', {id: payload.caller.contact_id}, {root: true});}
      notify.type = 'success';
      notify.msg = `${payload.caller.contact_name } has been added as an additional profile to ${payload.searchedContact.first} ${payload.searchedContact.last} `
    }).catch(e => {
      console.log(e);
      notify.type = 'error';
      notify.msg = 'Please try again.'
    })
    return notify;
    
  },
  async addAdditionalPhone({commit, dispatch}, payload){
    let data = { phone: payload?.caller?.phone_number, sms: 1, type: "Cell" };
    let notify = {msg: '', type: ''};
    await api.put(this, api.CHARM_CONTACT + payload.searchedContact.id + '/additional-phone', data).then(r => {
      if (payload.layout === 'charmCall') {commit('setAdditionalPhone', payload.caller.contact_id);}
      if (payload.layout === 'charmNotification') {dispatch('charmNotificationStore/addAdditionalPhone', payload.caller.contact_id, {root: true});}
      notify.type = 'success';
      notify.msg = `${payload?.formatted_phone_number} has been added as additional phone number to ${payload.searchedContact.first} ${payload.searchedContact.last}.`;
    }).catch(e => {
      console.log(e);
      notify.type = 'error';
      notify.msg = 'Please try again.';
    })
    return notify;
  },
  async makeOutgoingCall({commit}, body) {
    api.post(this, api.CHARM_OUTGOING_CALL, body)
    .then(r => { })
    .catch((error) => {
      console.log(error);
    });
  },

  answerCall({commit}) {
    commit('setAnswerCall');
  },

  updateConfirmation({commit},payload){
    commit('setupdateConfirmation',payload);
  },

  notificationInteraction({commit}) {
    commit('setNotificationInteraction')
  },
  notificationStatusHold({commit}) {
    commit('setNotificationStatusHold')
  },
  notificationStatusActive({commit}) {
    commit('setNotificationStatusActive');
  },
  getDetailsOfSearchedContact({commit},payload){
    if (payload?.id) {
      api.get(this, api.CHARM_CONTACT+payload.id).then(r =>{
        commit('setDetailsOfSearchedContact',r);
     }).catch(e => {
       console.log(e);
     });
    }
  },

  removeTwilioDetails({commit},payload) {
    commit('removeTwilioDetails', payload);
  },

  initilizeCharmCallState({commit}) {
    commit('initilizeCharmCallState');
  },

  async makeOutboundCall({dispatch, getters},{contact, agent}) {
    await dispatch('setCharmNotification',contact);
    await dispatch('setOutgoingCallData');
    dispatch('showCharmCallToast', true);
    const outgoingPhoneNumber = getters.outgoingPhoneNumber;
    if (outgoingPhoneNumber && contact?.property_id) {
      let data = {
        To: outgoingPhoneNumber,
        agent_id: agent?.id,
        property_id: contact?.property_id
      }
      await dispatch('makeOutgoingCall', data);
    }
  }
  ,
  refreshContactUnits({commit},payload){
    api.get(this, api.CHARM_CONTACT+payload).then(r =>{
      commit('setContactUnits',r);
   }).catch(e => {
     console.log(e);
   });
  },

  setDatacallWrap({commit},payload){
    commit('callWrapData',payload);
  },

  setLoadingAction({commit, dispatch}, value) {
    commit('setLoadingAction', value);
    if (!value.status) {
      dispatch('charmNotificationStore/setLoadingAction', value, {root: true});
    }
  },

  stopLoadingAnimation({commit}) {
    commit('stopLoadingAnimation')
  },

  missedCallToVoicemail({commit}, data) {
    commit('setMissedCallToVoicemail', data)
  },

  incomingCallToast({commit}, data) {
    commit('setIncomingCallToast', data)
  },
  
};
