export default {
	BILLING: 'companies/:company_id/billing/',
	ENTER_MONTHLY_BILLS: 'billing/enter-monthly-bills/',
	AGING_DETAIL: 'billing/get-aging-detail/',
	DELETE_PROPERTY_BILL: 'billing/delete-property-bill/',
	PROPERTY_BILL: 'billing/property-bill/',
	BILLING_HISTORY: 'billing/get-billing-history/',
	BILLING_SAVE_UTILITY: 'billing/save-utility/',
	BILLING_GET_UTILITY: 'billing/get-utilities/',
	BILLING_DELETE_UTILITY: 'billing/delete-utility/',
	BILLING_UTILITY: 'billing/utility/'
}