
const PLURALS = {
    "Day": "Days",
    "Stage": "Stages",
    "Action": "Actions",
    "Space Type": "Space Types",
    "State": "States",
    "Property": "Properties"
};

export default PLURALS;