import moment from "moment";

const timeDifferenceFn = {
    follwUpTime(time) {
        if(!time) return '';
        let result = '';
        let timeDiff = this.getTimeDiff(time);
        
        if (timeDiff.days) {
            result = timeDiff.days + "d";
            return timeDiff.days <= 2 ? result : '2d+';
        }
        if (timeDiff.hours) {
            result = timeDiff.hours + "h";
            return result;
        }
        if (timeDiff.minutes) {
            result = timeDiff.minutes + "m";
            return result;
        }
        if (timeDiff.seconds) {
            result = (timeDiff.seconds < 60) ? '<1m': '';
            return result;
        }
    },

    callDurationTime(time) {
        if (!time) return '00:00';
        let timeDiff = this.getTimeDiff(time);
        let difference = '';

        if (timeDiff.hours > 0) {
            difference += timeDiff.hours.toString() + ':';
        }

        difference += timeDiff.minutes.toString().padStart(2, '0') + ':'
        difference += timeDiff.seconds.toString().padStart(2, '0')
        return difference;
    },

    callBackTime(time) {
        if (!time) return "00:00";
        let timeDiff = this.getTimeDiff(time);
        let difference = "";
        
        if (timeDiff.days > 0) {
            if (timeDiff.days <= 2) {
            difference += timeDiff.days.toString() + "d";
            } else {
            difference = "2d+";
            }
        } else if (timeDiff.hours > 0) {
            difference += timeDiff.hours.toString() + "h";
        } else {
            difference += timeDiff.minutes.toString().padStart(2, "0") + ":";
            difference += timeDiff.seconds.toString().padStart(2, "0");
        }
        
        return difference;
    },
    
    getTimeDiff(time) {
        let startDate = moment(time).utc().local();
        let endDate = moment().utc().local();
        let timeDiff = { seconds: '', minutes: '', hours: '', days: '' };
        let diffInMilliSeconds = Math.abs(endDate - startDate) / 1000;
        
        timeDiff.days = Math.floor(diffInMilliSeconds / 86400);
        diffInMilliSeconds -= timeDiff.days * 86400;
        
        timeDiff.hours = Math.floor(diffInMilliSeconds / 3600) % 24;
        diffInMilliSeconds -= timeDiff.hours * 3600;
        
        timeDiff.minutes = Math.floor(diffInMilliSeconds / 60) % 60;
        diffInMilliSeconds -= timeDiff.minutes * 60;
        
        timeDiff.seconds = Math.floor(diffInMilliSeconds) % 60;
        
        return timeDiff;
    }
}

export default timeDifferenceFn;