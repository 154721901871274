import State from "./state";
import Mutations from "./mutations";
import Actions from "./actions";
import Getters from "./getters";

class CharmCall {
  constructor() {
    this.namespaced = true;
    this.state = State;
    this.mutations = Mutations;
    this.actions = Actions;
    this.getters = Getters;
  }
}
export default CharmCall;
