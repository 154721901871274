import api from '../../assets/api.js';

const types = {
  SET_ITEMS: 'SET_ITEMS',
  SET_BULK_EDIT_MODE: 'SET_BULK_EDIT_MODE'
}

class BulkEdit {
  constructor() {
    this.namespaced = true;
    this.state = {
      items: [],
      bulkEditMode: null
    };
    this.getters = {
      items: state => state.items,
      showBulkEditUnits: state => state.showBulkEditUnits,
      bulkEditMode: state => state.bulkEditMode
    };

    this.actions = {
      setItems({commit}, params) {
        commit(types.SET_ITEMS, params);
      },
      setEditMode({commit}, params) {
        commit(types.SET_BULK_EDIT_MODE, params);
      },
      resetAction({commit}, params){
        commit(types.SET_BULK_EDIT_MODE, null);
      }
    };
    this.mutations = {
      [types.SET_ITEMS](state, items) {
        state.items = items;
      },
      [types.SET_BULK_EDIT_MODE](state, params) {
        state.bulkEditMode = params;
      },
    }
  }
}

export default BulkEdit;
