export default {
    ONBOARDING: 'companies/:company_id/onboarding/',
    DOWNLOAD_SPREADSHEET_PRODUCTS:'companies/:company_id/onboarding/download-template?template=products',
    UPLOAD_SPREADSHEET_PRODUCTS:'companies/:company_id/onboarding/upload-template?template=products',
    DOWNLOAD_ERROR_TEMPLATE:'companies/:company_id/onboarding/download-errorfile',
    INVENTORY: 'companies/:company_id/onboarding/products/accounting/',
    ONBOARDING_DATA:'companies/:company_id/onboarding/onboarding-company',
    DOWNLOAD_SPREADSHEET_SPACEMIX:'companies/:company_id/onboarding/download-template?template=spacemix',
    UPLOAD_SPREADSHEET_SPACEMIX:'companies/:company_id/onboarding/upload-template?template=spacemix',
    ONBOARDING_EMAIL:'companies/:company_id/onboarding/onboarding-email',
    ONBOARDING_GLOSSARY:'companies/:company_id/onboarding/glossary-cms'
}

