import api from "../../assets/api.js";

class reportLibrary {
  constructor() {
    this.namespaced = true;
    //State
    this.state = {
      collections: [],
      collectionStore: [],
      storeSpaceGroup: [],
      scheduleType: "",
      addReportCollection: null,
      customCollReports: {},
      notificationId: []
    };
    //Getters
    this.getters = {
      collections: state => state.collections,
      collectionStore: state => state.collectionStore,
      getStoreSpaceGroup: state => state.storeSpaceGroup,
      getScheduleType: state=> state.scheduleType,
      getAddReportCollection: state => state.addReportCollection,
      getCustomCollReports: state => state.customCollReports,
      getNotificationId: state => state.notificationId
    };
    //Actions
    this.actions = {
      // To fetch all collections
      async fetchCollections({ commit }) {
        const collections = await api.post(this.$app, api.REPORT_COLLECTIONS);
        commit("setCollections", collections.collections);
        let colleactionData = collections.collections.map(collection => {
          collection.checkCollection = false;
          collection.reports.map(report => {
            report.checkReport = false;
            let reportDetails = {};
            if(report.report_category === "static") {
              if (!report.download_xls) { 
                reportDetails.file_type = 'pdf'
              }
              reportDetails.shareReportTimeSelected = report.end_date ? "This Month" : "Today";
            }
            report.reportDetails = reportDetails;
            return report;
          })
          if(['Bankers Box', 'Investor Reports'].includes(collection.name)) {
            commit('setCustomCollReports',{'collectionName': collection.name, 'reportIds': collection.reports.map(report => report.id)});
          }
          return collection;
        })
        commit('setCollectionStore', colleactionData);
      },

      //To pin/unpin report
      async pinUnpinReport({ commit, state }, payload) {
        let isPinned = payload.report.pinned
        await api.post(
          this.$spp,
          api.REPORT_PINNED +
            "?pinned=" +
            !isPinned +
            "&report_id=" +
            payload.report.id
        );

        const updatedCollection = state.collectionStore.map(col => {
            if(col.name === 'My Pinned Reports') {
              if (!isPinned) {
                //addpinnedItem
                commit("addPinnedReport", payload.report);
              } else {
                //remove pinned tem
                commit("removePinnedReport", payload.report);
              }
            } else {
              col.reports.map(colReport => {
                if (colReport.id === payload.report.id) {
                  if (!isPinned) {
                    colReport.pinned = 1;
                  } else {
                    colReport.pinned = 0;
                  }
                }
              });
            }
          return col;
        });
        commit("setCollectionStore", updatedCollection);
      },

      async getSpaceGroup({ commit, state }, report) {
        let r = await api.get(
          this,
          api.PROPERTIES + report.property_id + "/space-groups"
        );
        let payload = {
          collectionIndex: report.collectionIndex,
          index: report.index,
          spaceGroups: r.spaceGroups,
          propertyId: report.property_id
        };
        commit("setSpaceGroups", payload);
      },

      async getLoadedCollections({ commit, state }, index) {
        commit("getLoadedCollections", index);
      },
      deleteCollections({ commit, state }, payload){
        commit("deleteCollections",payload);
      },
      deleteStoreReport({commit, state}, payload) {
        commit("deleteStoreReport",payload);
      },
      updateReportDetailsState({commit, state}, payload) {
        let collectionData = state.collections.map(collection => {
          collection.reports.map(report => {
            if (report.id === payload.report.id) {
              report.reportDetails = payload.reportDetails;
            }
            return report;
          })
          return collection;
        })
        commit("setCollectionStore", collectionData);
      },
      removeReportCollectionStore({commit}, payload) {
        commit("setRemoveReportCollectionStore",payload);
        commit("removeReportIdsInCustomColl", { 'collectionName': payload.collectionName, 'reportId': payload.reportId });
      },
      setScheduleType({commit}, scheduleType) {
        commit("setScheduleType", scheduleType);
      },
      addReportCollectionStore({commit, state}, reportObject) {
        //needs update
        let updatedCollection = state.collectionStore.map(collection => {
           if (collection.name == reportObject.collectionType) {
            reportObject.reports.forEach(report => {
              collection.reports.push(report.report)
            })
            collection.reports.sort((a, b) =>
              a.name.localeCompare(b.name)
            );
           }
           return collection;
        })
        commit("setCollectionStore", updatedCollection);
        commit("addReportIdsInCustomColl", {'collectionName': reportObject.collectionType, 'reportIds': reportObject.reports.map(report => report.report.id)});
      }
    };
    //Mutations
    this.mutations = {
      setAddReportCollection: (state, value) => {
        state.addReportCollection = value;
      },
      setCollections: (state, payload) => {
        state.collections = [];
        state.collections = payload;
        state.collectionStore = [];
        state.collectionStore = payload;
        // for (let i = 0; i <= payload.length - 1; i++) {
        //   state.collectionStore.push({
        //     name: state.collections[i].name,
        //     type: state.collections[i].type,
        //     company_id: state.collections[i].company_id,
        //     contact_id: state.collections[i].contact_id,
        //     description: state.collections[i].description,
        //     length: state.collections[i].reports.length,
        //     id: state.collections[i].id,
        //     reports: state.collections[i].reports.splice(0, 9)
        //   });
        // }
      },

      setSpaceGroups: (state, payload) => {
        state.collectionStore[payload.collectionIndex].reports[payload.index].space_groups = [];
        const grp = {
          propertyId: payload.propertyId,
          spaceGroups: payload.spaceGroups
        };
        const exist = state.storeSpaceGroup.length ?
        state.storeSpaceGroup.find(el => el.propertyId == grp.propertyId) :
         state.storeSpaceGroup.push(grp);
        if(exist == undefined) { //new space group
          state.storeSpaceGroup.push(grp);
        }
      },

      getLoadedCollections: (state, index) => {
        const list = state.collections[index].reports.splice(0, 9);
        list.forEach(el => {
          state.collectionStore[index].reports.push(el);
        });
      },

      setCollectionStore: (state, payload) => {
        state.collectionStore = [];
        state.collectionStore = payload;
      },

      addPinnedReport: (state, payload) => {
          // payload.name = "My Pinned Reports";
          let index = state.collectionStore
            .map(col => col.name)
            .indexOf("My Pinned Reports");
          state.collectionStore[index].reports.push(payload);
          state.collectionStore[index].reports.sort((a, b) =>
            a.name.localeCompare(b.name)
          );
          state.collectionStore[0].length++;
      },

      removePinnedReport: (state, payload) => {
        let index = state.collectionStore
          .map(col => col.name)
          .indexOf("My Pinned Reports");

        state.collectionStore[index].reports.splice(
          state.collectionStore[index].reports.findIndex(
            report => report.id === payload.id
          ),
          1
        );
        state.collectionStore[0].length --;
      },
      deleteCollections:(state, payload)=>{
        state.collectionStore[payload.collectionIndex].reports.splice(
          state.collectionStore[payload.collectionIndex].reports.findIndex(
            report => report.id === payload.report.id
          ),
          1
        );
        state.collectionStore[payload.collectionIndex].length--;
      },
      deleteStoreReport:(state, payload)=> {
        state.collectionStore[0].reports.splice(
          state.collectionStore[0].reports.findIndex(
            report => report.id === payload.id
          ),
          1
        );
      },
      setRemoveReportCollectionStore:(state, payload)=> {
        state.collectionStore.find(el => el.id == payload.collectionId).reports = 
        state.collectionStore.find(el => el.id == payload.collectionId).reports.filter(e => e.id != payload.reportId)
      },
      setScheduleType:(state, scheduleType) => {
        state.scheduleType = scheduleType;
      },
      resetScheduleType:(state) => {
        state.scheduleType = "";
      },
      setCustomCollReports:(state, payload) => {
        state.customCollReports[payload.collectionName] = payload.reportIds;
      },
      addReportIdsInCustomColl: (state, payload) => {
        state.customCollReports[payload.collectionName].push(... payload.reportIds);
      },
      removeReportIdsInCustomColl: (state, payload) => {
        state.customCollReports[payload.collectionName] = state.customCollReports[payload.collectionName].filter(reportId => reportId !== payload.reportId);
      },
      setNotificationId: (state, id) => {
        state.notificationId.push(id);
        console.log("id", state.notificationId)
      }
    };
  }
}

export default reportLibrary;
