import api from "../../assets/api.js";

class TenantPaymentsApplication {
  constructor() {
    this.namespaced = true;

    this.state = {
      application: null,
      property_id: null,
      current_property: null
    };

    this.getters = {
      getApplication: (state) => state.application,
      getPropertyID: (state) => state.property_id,
      getCurrentProperty: (state) => state.current_property,
    };

    this.mutations = {
      setApplication: (state, payload) => {
        state.application = payload;
      },
      setPropertyID: (state, payload) => {
        state.property_id = payload;
      },
      setCurrentProperty: (state, payload) => {
        state.current_property = payload;
      }
    };

    this.actions = {
      async fetchTenantPaymentsApplication({ state, commit }, data) {
        try {
          let response = await api.get(this.$app, api.TENANT_PAYMENTS_APPLICATIONS, { property_id: data.property_id });
          commit("setApplication", response.application);
          commit("setPropertyID", data.property_id);
          commit("setCurrentProperty", data.current_property);
        } catch (error) {
          commit("setApplication", null);
          commit("setPropertyID", data.property_id);
          commit("setCurrentProperty", data.current_property);
          throw error;
        }
        return state.application;
      },
      async updateTenantPaymentsApplication({ state, dispatch }, data) {
        try {
          await api.put(this.$app, api.TENANT_PAYMENTS_APPLICATIONS + state.application.id, data.form);
          await dispatch('fetchTenantPaymentsApplication', { property_id: state.property_id, current_property: state.current_property });
        } catch (error) {
          throw error;
        }
      },
      async createTenantPaymentsApplication({ state, dispatch }, data) {
        try {
          data.form.property_id = state.property_id;
          await api.post(this.$app, api.TENANT_PAYMENTS_APPLICATIONS, data.form);
          await dispatch('fetchTenantPaymentsApplication', { property_id: state.property_id, current_property: state.current_property });
        } catch (error) {
          throw error;
        }
      },
      async validateRegistrationCode({ state }, data) {
        try {
          let response = await api.get(this.$app, api.TENANT_PAYMENTS_APPLICATIONS + '/tier', { registration_code: data.registration_code, property_id: state.property_id });
          return response;
        } catch (error) {
          throw error;
        }
      },
    };
  }
}

export default TenantPaymentsApplication;