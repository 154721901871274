import api from '../../assets/api.js';
import Vue from 'vue';

const types = {
    SET_TASKS: 'SET_TASKS',
    SET_EVENTS: 'SET_EVENTS',
    SET_EVENT_TYPES: 'SET_EVENT_TYPES',
    SET_TASKS_COUNT: 'SET_TASKS_COUNT', 
    TASK_CENTER_OPEN: 'TASK_CENTER_OPEN',
    SET_FILTERS: 'SET_FILTERS',
    SET_ACTIVE_SECTION: 'SET_ACTIVE_SECTION',
    SET_SUBCATEGORY: 'SET_SUBCATEGORY',
    SET_LOADING: 'SET_LOADING',
    MARK_TASK: 'MARK_TASK',
    ALLOW_SCROLL: 'ALLOW_SCROLL',
};

let sections = [
    {
        title: 'Pending Move-Ins',
        name: 'moveIn',
        task_categories: ['pending_move_in', 'pending_transfer'],
        button: {
            title: 'Pending Move-Ins',
            class: "text-center px-2 pl-1 py-0",
            action:'moveIn',
            count: ()=>{  return 4 },
            icon: {
                size: 24,
                class: 'pb-1',
                color: '#101318',
                mdi: 'mdi-account-box'
            }
        }, 
        icon: {
            size: 26,
            class: 'pb-1',
            color: '#101318',
            mdi: 'mdi-account-box'
        }
    },
    {
        title: 'Lead Follow Up',
        name: 'followUp',
        task_categories: ['hotlead', 'cart_abandonment', 'lead_follow_up', 'new_web_reservation', 'reservation_follow_up', 'new_web_lead', 'new_reservation', 'new_lead', 'coupon_clicks', 'reservation_failure'],
        button: {
            title: 'Lead Follow Up',
            action: 'followUp',
            class: "text-center px-2 pl-1 py-0", 
            count: ()=>{ return 8 },
            icon: {
                size: 24,
                class: 'pb-1',
                color: '#101318',
                mdi: 'mdi-account-plus'
            }, 
        }, 
        icon: {
            size: 24,
            class: 'pb-1',
            color: '#101318',
            mdi: 'mdi-account-plus'
        }
    },

    {
        title: 'Collection Call', 
        name: 'collectionCall',
        task_categories: ['collection_calls'],
        button: {
            action: 'collectionCall',
            title: 'Collection Call',
            class: "text-center px-2 pl-1 py-0",
            count: ()=>{ return 12 },
            icon: {
                size: 24,
                class: 'pb-1',
                color: '#101318',
                mdi: 'mdi-cash-usd-outline'
            },
        },
        icon: {
            size: 24,
            class: 'pb-1',
            color: '#101318',
            mdi: 'mdi-cash-usd-outline'
        }
    },
    {
        title: 'Move-Out',
        name: 'moveOut',
        icon: {
            size: 24,
            class: 'pb-1',
            color: '#101318',
            mdi: 'mdi-system-navigation-custom-1'
        },
        task_categories: ['move_out'], 
        button: {
            title: 'Move-Out',
            class: "text-center px-2 pl-1 py-0",
            action:'moveOut',
            count: ()=>{ return 1 },
            icon: {
                size: 24,
                class: 'pb-1',
                color: '#101318',
                mdi: 'mdi-system-navigation-custom-1'
            },
        },

    },
    {
        title: 'Delinquent Tasks',
        name: 'delinquency',
        icon: {
            size: 24,
            class: 'pb-1',
            color: '#101318',
            mdi: 'mdi-shield-star'
        },
        task_categories: ['overlock_space', 'lock_removal', 'space_inventory', 'cut_lock_schedule_auction', 'auction_day', 'auction_payment' ],
        button: {
            title: 'Delinquent Tasks',
            class: "text-center px-2 pl-1 py-0",
            action:'delinquency',
            count: ()=>{ return 1 },
            icon: {
                size: 24,
                class: 'pb-1',
                color: '#101318',
                mdi: 'mdi-shield-star'
            },
        }
    },
    {
        title: 'Task Center',
        name: 'all',
        icon: {
            size: 30,
            mdi: 'mdi-playlist-check'
        }
    },
    {
        title: 'List',
        name:'seeList',
        icon: {
            size: 30,
            mdi: 'mdi-playlist-check'
        }
    },



]


class TaskCenter {
	constructor(){
		this.namespaced = true;
		this.state = {
            sections: sections,
          
            tasks: [],
            events: [],
            eventTypes: [],
            tasksCounter: {},
            tasksGroupId: '',
            subcategory: '',
            is_open: false,
            filters: [],
            activeSectionName: 'all',
            loading: false,
            allow_scroll: true
		};
		this.getters = {
            buttons: state => state.sections.filter(s => s.button).map(s => s.button),
            sections: state => state.sections,
            tasks: state => state.tasks,
            events: state => state.events,
            eventTypes: state => state.eventTypes,
            tasksCount: state => state.tasksCounter,
            tasksCenterOpen: state => state.is_open,
            filters: state => state.filters,
            activeSectionName: state => state.activeSectionName, 
            subcategory: state => state.subcategory, 
            activeSection: state => state.sections.find(s => s.name == state.activeSectionName), 
            allowScroll: state => state.allow_scroll,
            tasksLoading: state => state.loading,
            numTasks: (state, getters) => {

                if(state.filters.length){
                    let count = state.filters.reduce((a,b) => {
                        let event_type  = state.eventTypes.find(et => et.id === b); 
                        if(!event_type || !state.tasksCounter[event_type.slug]) return a; 
                        return a + state.tasksCounter[event_type.slug].count
                    }, 0);    
                    return count;
                }
               
                try{

                    if(state.tasksCounter && state.tasksCounter[getters.activeSection.name]){
                        return state.tasksCounter[getters.activeSection.name].count; 
                    }
                } catch(err){
                    console.log("err", err)
                }
                return 0
            }

            
		};
		this.actions = {

            async loadAllCounts({commit, state}){
                    console.log("loadAllCounts")

                    let list = state.sections.reduce((a, b) => {
                        if(!b.task_categories) return; 
                        let ids = b.task_categories.map(tc => tc == state.eventTypes.find(e => e.slug === tc).id );
                        return a.concat(ids); 
                    }, []);
                

                let param = list.reduce((a,b) => a + `&event_type_id[]=${b}`, '');
                let r = await api.get(this.$app, api.TASKS + 'count?' + param); 
                console.log("loadAllCounts r", r)
            }, 


            getEvents({commit}){
                api.get(this.$app, api.EVENTS).then( payload => {
                    commit(types.SET_EVENTS, payload.events);
                    console.log('events: ', payload.events)
                }).catch(e => { console.log('GET ERROR ', e); });
            },
            


            
			async getTasks({ commit, state, dispatch }, data = {}){
                
                commit(types.SET_LOADING, true);
                
                let offset = data.reset ? 0 : state.tasks.length
                let { role_ids } = data;
                let event_type_filters = [];
                
                if(state.subcategory){
                    event_type_filters.push(state.subcategory); 
                } else {
                    event_type_filters = state.filters;
                }
                
                let filter_path =  event_type_filters.reduce((a,b) => a + `&event_type_id[]=${b}`, '');
                let roles_path = '';
                if(role_ids && role_ids.length > 0){
                    roles_path =  role_ids.reduce((a,b) => a + `&role_ids[]=${b}`, '');
                }
                if(data.reset){
                    await  commit(types.SET_TASKS, {reset: true});
                
                }
                let path = `?limit=20&offset=${ offset}${filter_path}${roles_path}`
                
                let r = await api.get(this.$app, api.TASKS.slice(0, -1) + path)

                
                if(!r.tasks.length) {
                    commit(types.ALLOW_SCROLL, false);
                }
                if(data.reset){
                    commit(types.ALLOW_SCROLL, true);
                }


                dispatch('setTaskCounts', { role_ids });
                // dispatch('getTasksCount', event_type_filters);
                //let count = await api.get(this.$app, api.TASKS + 'count' + path)
                
                
                commit(types.SET_TASKS, {
                    tasks: r.tasks,
                    reset: data.reset
                });
                

                commit(types.SET_LOADING, false);
            
            },

            async getGroupTasks({ commit, state }, data = {}){
                
                if (data.groupId) {
                    state.tasksGroupId = data.groupId;
                }

                let { role_ids } = data;
                let roles_path = '';
                if(role_ids && role_ids.length > 0) {
                    roles_path =  role_ids.reduce((a,b) => a + `&role_ids[]=${b}`, '');
                }

                commit(types.SET_TASKS, {reset: true});
                let gId = data.groupId ? data.groupId : state.tasksGroupId ? state.tasksGroupId : '';
                let params = data.params;
                return await api.get(this.$app, api.TASKS + 'groups/' + gId + params + roles_path).then(payload => {
                    commit(types.SET_TASKS, {tasks: payload.tasks});
                    return payload.tasks;
                });
            },
            async getEventTypes({ commit }, type = ''){
                commit(types.SET_EVENT_TYPES, []);
                let payload = await api.get(this.$app, api.EVENTS + 'event-types' + (type ? ('?type=' + type) : ''))
                commit(types.SET_EVENT_TYPES, payload.event_types);
            },
            async setTaskCounts({ commit, state, dispatch }, data){
                
                if(!state.eventTypes.length){
                    await dispatch('getEventTypes', 'task');
                }
                
                let id_list = []

                let list = state.sections.map(s => s.task_categories).filter(i => i && i.length);
                
                console.log("list", list)
                let names = Array.prototype.concat.apply([], list);
                
                id_list = names.map(tc => {
                    let type = state.eventTypes.find(e => e.slug === tc)   
                    return type ? type.id : ''
                }); 
                console.log("state.eventTypes", state.eventTypes)
                if(state.subcategory){
                    id_list.push(state.subcategory);
                } else {
                    id_list = id_list.concat(state.filters)
                
                }

                //let params = id_list.reduce((a,b) => a + `&event_type_id[]=${b}`, '');
                
                await dispatch('getTasksCount', { id_list, role_ids: data.role_ids })

            },    
            async getTasksCount({ commit, state }, { id_list, role_ids } ){
                console.log("id_list", id_list)
                let params = id_list.reduce((a,b) => a + `&event_type_id[]=${b}`, '');
                if(role_ids && role_ids.length > 0){
                    role_ids = role_ids.reduce((a,b) => a + `&role_ids[]=${b}`, '');
                } else {
                    role_ids = ''
                }
                let url = api.TASKS + 'count?' + params + role_ids;
                let r = await api.get(this.$app, url); 

                let tasks_count = r.tasks_count;
                
                console.log("r", r)
                console.log("tasks_count", tasks_count)
                id_list.map(id => {
                    if(!tasks_count.find(t => t.event_type_id === id)){
                        tasks_count.push({
                            event_type_id: id,
                            event_type_name: state.eventTypes.find(et => et.id == id).slug, 
                            count: 0
                        })
                    }
                })


                console.log("GOT NEW COUNT", tasks_count)
                commit(types.SET_TASKS_COUNT, tasks_count);
            },
            resetTasks({commit}){
                commit(types.SET_TASKS, {reset: true});
            },
            async removeOverlock({commit, dispatch}, payload) {
                await api.delete(this.$app, api.UNITS + payload.unit_id + "/overlock" + (payload.lease_id ? ('?lease_id=' + payload.lease_id) : '')).then(r => {});
            },
            async overlockSpace({commit, dispatch}, payload) {
                await api.post(this.$app, api.UNITS + payload.unit_id + "/overlock", { lease_id: payload.lease_id }).then(r => {});
            },

            async removeAllOverlocks({commit, dispatch}, payload) {
                await api.delete(this.$app, api.UNITS + "/overlock-all");
                dispatch('getTasks', {reset: true})
     
                
            },
            async overlockAllSpaces({commit, dispatch}, payload) {
                await api.post(this.$app, api.UNITS + "/overlock-all");
                dispatch('getTasks', {reset: true})
            },

            async setLoading({commit, dispatch}, payload) {
                commit(types.SET_LOADING, payload);
            },
            async setTaskCenterOpen({commit, dispatch}, payload) {
                if(!payload){
                    commit(types.SET_FILTERS, []);
                    commit(types.SET_ACTIVE_SECTION, 'all');
                    commit(types.SET_SUBCATEGORY, null);
                    commit(types.SET_TASKS, {reset: true});
                    
                }
            
                commit(types.TASK_CENTER_OPEN, payload);
            },
            async setFilters({commit, dispatch}, payload) {
                console.log("payload" , payload); 
                commit(types.SET_FILTERS, payload); 
            },
            async setActiveSection({commit, dispatch}, sectionName) {
                
                commit(types.SET_ACTIVE_SECTION, sectionName); 
            },

            async setSubcategory({commit, dispatch}, subcategory) {
                
                commit(types.SET_SUBCATEGORY, subcategory); 
            },

            async markTask({commit}, params) {
                
                commit(types.MARK_TASK, params); 
            },

		};
		this.mutations = {
			[types.SET_TASKS](state, payload) {
                
                let tasks = [];
                // format tasks
                payload.tasks = payload.tasks || []
                
                    for(let i = 0; i < payload.tasks.length; i++){
                        if( payload.tasks[i].EventType &&  payload.tasks[i].EventType.icon &&  payload.tasks[i].EventType.icon.indexOf('Google Icon:') >=0){
                            payload.tasks[i].EventType.icon = payload.tasks[i].EventType.icon.replace('Google Icon:', '')
                        }
                    }
                    
                //         let t = {
                //             Contact: task.Event.Lease && task.Event.Lease.Tenants && task.Event.Lease.Tenants[0].Contact,
                //             Unit: task.Event.Lease && task.Event.Lease.Unit,
                //             Lease: task.Event.Lease, 
                //             AssignedTo: task.AssignedTo,
                //             Event: {
                //                 id: task.Event.id,
                //                 created_at: task.Event.created_at,
                //                 details: task.Event.id,
                //                 duration: task.Event.id,
                //                 start_date: task.Event.start_date,
                //                 title: task.Event.event_type.slug === "task" ? task.Event.title: task.Event.event_type.name,
                //                 timestamp: task.Event.timestamp,
                //                 group_id: task.Event.group_id,
                //                 can_dismiss: task.Event.can_dismiss,
                //                 upload_id: task.Event.upload_id,
                //                 btn_text: task.Event.btn_text,
                //                 lease_id: task.Event.lease_id,
                //                 EventType: task.Event.event_type,
                //                 Rate_Change: task.Event.Rate_Change,
                //                 text: task.Event.event_type.slug === "task" ? task.details: task.Event.event_type.text,
                //             }, 
                //             original_date: task.original_date,
                //             details: task.details, 
                //             id: task.id, 
                //             link_to_resource: task.link_to_resource,
                //             count: payload.count,
                //             completed_by: payload.completed_by,
                //             created_by: payload.created_by, 
                //             completed: payload.completed, 
                //             type: payload.type
                //         }
                        
                //         if(t.Event.EventType && t.Event.EventType.icon && t.Event.EventType.icon.indexOf('Google Icon:') >=0){
                //             t.Event.EventType.icon = t.Event.EventType.icon.replace('Google Icon:', '')
                //         }

                //         if(t.Event.EventType && task.Event.event_type.slug === "task"){
                //             t.Event.EventType.btn_text = "Complete Task"
                //         }
                        
                //         tasks.push({
                //             count: payload.tasks[i].count,
                //             type: payload.tasks[i].type,
                //             task: t,
                //         }); 
                    
                
               
                if(payload.reset){
                    state.tasks = payload.tasks;
                } else {
                    state.tasks = state.tasks.concat(payload.tasks);
                }
                
            },
            [types.SET_EVENTS](state, payload) {
				state.events = payload;
			},
            [types.SET_EVENT_TYPES](state, payload) {
				state.eventTypes = payload;
			},
            [types.SET_TASKS_COUNT](state, payload) {
                for (let i = 0; i < payload.length; i++ ){
                    Vue.set(state.tasksCounter, payload[i].event_type_name,  payload[i])
                }
			}, 
            [types.TASK_CENTER_OPEN](state, payload) {
				state.is_open = payload;
            },
            [types.SET_FILTERS](state, payload) {
				state.filters = payload;
            }, 
            [types.SET_ACTIVE_SECTION](state, sectionName) {
				state.activeSectionName = sectionName;
            },
            [types.SET_SUBCATEGORY](state, subcategory) {
				state.subcategory = subcategory;
			}, 
            [types.SET_LOADING](state, loading) {
                console.log('loading', loading)
				state.loading = loading;
			}, 
            [types.ALLOW_SCROLL](state, allow) {
                console.log("allow", allow); 
				state.allow_scroll = allow;
			}, 
            [types.MARK_TASK](state, params) {
                console.log("params", params)  
                let taskIndex = state.tasks.findIndex(t => t.task.id === params.task_id);
                state.tasks[taskIndex].task.completed = params.complete;
                
                
			}
		}
	}
}

export default TaskCenter;
