
/**
 * function to  generate the property rate managemant API URL
 * @param {String} property_id - hashed id of selected property
 * @returns {String} - generated URL with property id.
 */
const getPropertyRateManagementUrl = (property_id) => `companies/:company_id/properties/${property_id}/rate-management/`

/**
 * function to  generate the property space group rate managemant API URL
 * @param {String} property_id - hashed id of selected property
 * @param {String} space_group_id - hashed id of selected space group
 * @returns {String} - generated URL with property id and space group id.
 */
const getSpaceGroupRateManagementUrl = (property_id, space_group_id) => `${getPropertyRateManagementUrl(property_id)}space-groups/${space_group_id}`

/**
 * function to  generate the property space group rate managemant API URL
 * @param {String} property_id - hashed id of selected property
 * @param {String} space_group_id_hash - hashed id of selected space group
 * @returns {String} - generated URL with property id and space group id.
 */
 const getPropertySpaceGroupPromotionsUrl = (property_id, space_group_id_hash) => `companies/:company_id/properties/${property_id}/space-groups/${space_group_id_hash}/promotions`


export default {
	getPropertyRateManagementUrl,
	getSpaceGroupRateManagementUrl,
	getPropertySpaceGroupPromotionsUrl,
	COMPANY_RATE_CAMPAIGN: 'companies/:company_id/rate-management/rate-campaigns/', 
	COMPANY_RATE_MANAGEMENT: 'companies/:company_id/rate-management/',
}