import api from '../../assets/api.js'
import Vue from "vue";

const types = {
    SET_PROFILES: 'SET_PROFILES',
    SET_PROPERTY_TAX_RATES: 'SET_PROPERTY_TAX_RATES',
    SET_PROPERTY_TAX_PROFILES: 'SET_PROPERTY_TAX_PROFILES',
    SET_ACCOUNT_ALERT: 'SET_ACCOUNT_ALERT'
}

class TaxProfile {
    constructor() {
        this.namespaced = true;
        this.state = {
            taxProfiles: [],
            propertyTaxRates: [],
            propertyTaxProfiles: [],
            showIncomeAccountAlert: false
        };

        this.getters = {
            taxProfiles: state => state.taxProfiles,
            propertyTaxRates: state => state.propertyTaxRates,
            propertyTaxProfiles: state => state.propertyTaxProfiles,
            showIncomeAccountAlert: state => state.showIncomeAccountAlert
        };

        this.actions = {
            getTaxProfiles ({commit}) {
                api.get(this.$app, api.TAX_PROFILE).then(r => {
                    commit(types.SET_PROFILES, r.tax_profiles);
                    r.tax_profiles.filter(t => !t.account_id).length ? commit(types.SET_ACCOUNT_ALERT, true) : commit(types.SET_ACCOUNT_ALERT, false);
                });
            },
            async getPropertyTaxRates({commit}, data = null) {
                await api.get(this.$app, api.PROPERTIES + data.property_id + '/tax-rates').then(r => {
                    commit(types.SET_PROPERTY_TAX_RATES, r.tax_rates);
                });
            },
            async addTaxProfile ({dispatch}, data = null) {
                let r = await api.post(this.$app, api.TAX_PROFILE, data);
                dispatch('getTaxProfiles');
                return r;
            },
            async editTaxProfile({dispatch}, data = null) {
                let payload = {
                    name: data.name,
                    state: data.state,
                    account_id: data.account_id,
                    tax_rate: data.tax_rate,
                }
                await api.put(this.$app, api.TAX_PROFILE + data.id, payload).then(r => {
                    dispatch('getTaxProfiles');
                    return r;
                });
            },
            deleteTaxProfile({dispatch}, data) {
                api.delete(this.$app, api.TAX_PROFILE + data.id).then(r => {
                    dispatch('getTaxProfiles');
                    return r;
                });
            }
        };

        this.mutations = {
            [types.SET_PROFILES](state, payload){
                state.taxProfiles = payload;
            },
            [types.SET_PROPERTY_TAX_RATES](state, payload){
                state.propertyTaxRates = payload;
            },
            [types.SET_ACCOUNT_ALERT](state, payload){
                state.showIncomeAccountAlert = payload;
            }
        }
    }
}

export default TaxProfile;
