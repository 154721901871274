export default {
	GET_DOCUMENT: 'companies/:company_id/documents/sign-document/',
	SAVE_SIGNATURE: 'companies/:company_id/documents/save-signature/',
	GENERATE_PDF: 'companies/:company_id/documents/generate-pdf',
	DOCUMENT: 'companies/:company_id/documents/',
	GENERATE_DOCUMENT: 'companies/:company_id/documents/generate-document/',
	GET_MERGE_FIELDS: 'companies/:company_id/documents/get-merge-fields/',
	FIELDS: 'companies/:company_id/documents/fields/',
	SET_UP_SIGNERS: 'companies/:company_id/documents/set-up-signers/',
	SEND_SIGN_LINK: 'companies/:company_id/documents/send-sign-link/',
	GET_SIGN_LINK: 'companies/:company_id/documents/get-sign-link/',
	RECORD_SIGN_ACTION: 'companies/:company_id/documents/record-sign-action/',
	RECORD_SIGN_STATUS: 'companies/:company_id/documents/record-sign-status/',
	DOCUMENT_TYPES: 'companies/:company_id/documents/types/',
	PUBLIC_DOCUMENTS: 'companies/:company_id/documents/public/',
	DIRECT_REFUNDS: 'companies/:company_id/direct-refund/'
}
