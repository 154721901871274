export default {
	SETTINGS_GET_CATEGORIES: 'companies/:company_id/settings/get-categories/',
	SETTINGS_BILLING: 'companies/:company_id/settings/billing/',
	SETTINGS_GET_KEYS: 'companies/:company_id/settings/get-keys/',
	SETTINGS_CREATE_KEY: 'companies/:company_id/settings/create-key/',
	SETTINGS_DELETE_KEY: 'companies/:company_id/settings/delete-key/',
	SETTINGS_SAVE: 'companies/:company_id/settings/save/',
	SETTINGS_SAVE_CATEGORY: 'companies/:company_id/settings/save-category/',
	SETTINGS_GET_QUICKBOOKS: 'companies/:company_id/settings/quickbooks/',
	SETTINGS_GET_LINK_QB: 'companies/:company_id/settings/link-qb',
	SETTINGS_QB_CALLBACK: 'companies/:company_id/settings/qb-callback/',
	SETTINGS_QB_DISCONNECT: 'companies/:company_id/settings/disconnect-qb/',
	SETTINGS_GET_BRIVO: 'companies/:company_id/settings/brivo/',
	SETTINGS_NOTIFICATIONS: 'companies/:company_id/settings/notifications/',
	SETTINGS_NOTIFICATION_TYPES: 'companies/:company_id/settings/notification-types/',
	SETTINGS: 'companies/:company_id/settings/',
	SETTINGS_UNSUBSCRIBE: 'companies/:company_id/applications/unsubscribe/',
}
