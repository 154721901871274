import Vue from 'vue'
import api from "../../assets/api";
import moment from "moment";


const state = {
	tenants: [],
	unit: {
		id: '',
		property_id: '',
		number: '',
		price: '',
		Address: {
			address: '',
			city: '',
			state: '',
			zip: '',

		}
	},
  invoice:{},
	lease:{
		id: null,
		unit_id: '',
		rent: 0,
		security_deposit: 0,
		type: '',
		start_date: '',
		end_date: '',
		bill_day: 1,
		promotion_id: null,
		send_invoice: 1,
		notes: '',
		decline_insurance: false
	},
	template: {},
	insurance: {},
	isLastMonthRent:false,
	billed_months: 0,
	promotion:{},
	paymentMethods: [],
	documents: [],
	services:[],
	new_lease_property:{
		id: '',

	},
	new_lease_hours:{},
  active_contact_id: '',
	new_lease_contact: {
		first: '',
		last: '',
		email: '',
		Phones:[ {
			type: 'Cell',
			phone: '',
			sms: true
		}],
    lead: {
		  property_id: '',
      category_id: '',
      unit_id: ''
    }
	},
	contact: [],
	promotions: [],
  payment:{},
  paymentMethod:{},
  reservation: {
	  reservation_id: null,
    contact_id: null,
    Lease: {}
  },
  tax_rate: 0
};


const getters = {
	leaseContact() { return state.contact },
	leaseInvoice() { return state.invoice },
	leaseTemplate() { return state.template },
	lease() { return state.lease },
	leaseUnitId() { return state.lease.unit_id },
	leaseId() { return state.lease.id },
	leaseType() { return state.lease.type },
	leaseStartDate() { return state.lease.start_date },
	leaseEndDate() { return state.lease.end_date },
	leaseRent() { return state.lease.rent },
	leaseSecurityDeposit() { return state.lease.security_deposit },
	leaseBillDay() { return state.lease.bill_day },

	leaseSendInvoice() { return state.lease.send_invoice },
	leaseTerms() { return state.lease.terms },
	leaseNotes() { return state.lease.notes },
	leaseDeclineInsurance() { return state.lease.decline_insurance },

	leaseUnit() { return state.unit },
	leaseUnitPropertyId() { return state.unit.property_id },
	leaseUnitType() { return state.unit.type },
  leasePromotion() { return state.promotion },
  leaseTenants() { return state.tenants },
	leaseServices() {  return state.services },
	leaseInsurance() { return state.insurance },
	billed_months() { return state.billed_months },
  taxRate() { return state.tax_rate },
  reservation() { return state.reservation },

	newLeaseProperty(){ return state.new_lease_property },
	newLeaseHours(){ return state.new_lease_hours },
	newLeaseContact(){ return state.new_lease_contact },
	getPromotions(){ return state.promotions },
	leasePayment(){ return {
	  payment: state.payment,
    paymentMethod: state.paymentMethod,
  }},

	activeContactId() { return state.active_contact_id }

};

const mutations = {

	updateUnitId(state, payload) { state.lease.unit_id = payload; },

  updateType(state, payload) { state.lease.type = payload; },

  updateStartDate(state, payload) { state.lease.start_date = payload; },

  updateEndDate(state, payload) { state.lease.end_date = payload; },

  updateRent(state, payload) { state.lease.rent = payload; },

  updateSecurityDeposit(state, payload) { state.lease.security_deposit = payload; },

  updateBillDay(state, payload) { state.lease.bill_day = payload; },

  updateSendInvoice(state, payload) { state.lease.send_invoice = payload; },

  updateTerms(state, payload) { state.lease.terms = payload; },

  updateNotes(state, payload) { state.lease.notes = payload; },

  updatePromotion(state, payload) {
    state.lease.promotion_id = payload.id;
	  state.promotion = payload;
  },

  updateLeaseId(state, payload) { state.lease.id = payload; },

  updateDeclineInsurance(state, payload) {
		state.lease.decline_insurance = payload;
	},

	updateBilledMonths(state, payload) {
		state.billed_months = payload;
	},

	addContact(state, payload) {
	  state.contact = payload;
	},

  setActiveContactId(state, payload){
    state.active_contact_id = payload;
  },

	setUnit(state, payload) {
		state.unit = {
			id: payload.id,
			number: payload.number,
			property_id: payload.property_id,
			type: payload.type,
			price: payload.price,
			Address: {
				address: payload.Address.address,
				city: payload.Address.city,
				state: payload.Address.state,
				zip: payload.Address.zip
			}
		};
	},

	setLease(state, payload) {

		if(!payload) return;
		state.lease = {
			id: payload.id,
			unit_id: payload.unit_id,
			rent: payload.rent,
			security_deposit: payload.security_deposit,
			type: payload.end_date ? "Fixed Length": "Month to Month",
			start_date: payload.start_date,
			end_date: payload.end_date,
			bill_day: payload.bill_day,
			promotion_id: payload.promotion_id,
			send_invoice: payload.send_invoice,
			notes: payload.notes,
			terms: payload.terms,
			decline_insurance: payload.decline_insurance
		};
	},

	setServices(state, payload){
		state.services = payload;
	},

  setInsurance(state, payload){
		state.insurance = payload;
	},

  setTenants(state, payload){
		state.tenants = payload;
	},

  setPaymentMethods(state, payload){
		state.paymentMethods = payload;
	},

  setReservation(state, payload){

		state.reservation = payload;
	},

  removeService(state, payload){
		state.services.splice(state.services.indexOf(payload), 1);
	},

  removePayment(state, payload){
    state.payment = {};
  },

  resetLease(state, payload = {}){
		state.lease ={
			id: null,
			unit_id: '',
			rent: 0,
			security_deposit: 0,
			type: '',
			start_date: '',
			end_date: '',
			bill_day: '',
			promotion_id: '',
			send_invoice: 1,
			notes: '',
			terms: '',
      decline_insurance: 0,
		}

		state.unit = {
			property_id: '',
			number: '',
			price: '',
			Address: {
				address: '',
				city: '',
				state: '',
				zip: '',
			}
		}

		state.promotion = {};
		state.services = [];
		state.paymentMethods = [];
		state.insurance = {};
		state.template = {};
		state.billed_months = 0;
		//if(!payload.save_tenants){
    state.tenants = [];
    //}
		state.payment = {};
		state.new_lease_property = {};
		state.new_lease_hours = {};
		state.documents = [];
		state.isLastMonthRent = false;
    state.new_lease_property ={
      id: ''
    };
    state.active_contact_id = '';
    state.contact = [];
    state.promotions = [];
    if(!payload.save_reservation){
      state.reservation = {
        reservation_id: null,
          contact_id: null,
          Lease: {}
      };
    }
	},
  //
  // resetReservationId(state){
  // },

  resetNewLeaseContact(state){
    state.new_lease_contact = {
      first: '',
      last: '',
      email: '',
      Phones:[ {
        type: 'Cell',
        phone: '',
        sms: true
      }],
      lead: {
        property_id: '',
        category_id: '',
        unit_id: ''
      }
    };
  },

	resetUnit(state){
		state.unit = {
			property_id: '',
			number: '',
			price: '',
			Address: {
				address: '',
				city: '',
				state: '',
				zip: '',
			}
		}
	},


  setTemplate(state, payload){
		state.template = payload;
	},

  setNewLeaseProperty(state, payload){
		state.new_lease_property = payload;
	},

  setNewLeaseHours(state, payload){
		state.new_lease_hours = payload;
	},

  setNewLeaseContact(state, payload){
		state.new_lease_contact = payload;
	},

  setNewLeaseLead(state, payload){
    state.new_lease_contact.Lead = payload;
  },

  setPromotions(state, payload){
		state.promotions = payload;
	},

  setPayment(state, payload){

    state.payment = payload.payment;
    state.paymentMethod = payload.paymentMethod;
  },
  setLeaseInvoice(state,payload){
    state.invoice = payload.invoice;
  },
  setTaxRate(state,payload){
    state.tax_rate = payload;
  },

};

const actions = {

  async getEstimate({getters, commit}, data){

    let lease = {
      billed_months: getters.billed_months,
      dryrun : true
    };

    let payment_info  = getters.leasePayment;
    lease.payment = payment_info.payment;
    if(!lease.payment.payment_method_id && payment_info.paymentMethod.type){
      lease.paymentMethod = payment_info.paymentMethod;
    }

   // lease.total = this.totalSum;

    lease.payment = {
      id: payment_info.payment.payment_id,
      payment_method_id: payment_info.payment.payment_method_id,
      type: payment_info.payment.type,
      number: payment_info.payment.number,
      ref_name: payment_info.payment.ref_name,
      source: payment_info.payment.source,
      amount: payment_info.payment.amount,
      date: payment_info.payment.date,
      contact_id: payment_info.payment.contact_id,
      property_id: payment_info.payment.property_id,
      authorization: payment_info.payment.authorization
    };

    let r = await api.post(this.$app, api.LEASES + getters.lease.id + '/finalize', lease);
    commit('setLeaseInvoice', r);

  },

	async getPropertyInfo({getters, commit}, data){
		if(!getters.newLeaseProperty.id || getters.newLeaseProperty.id !==  data.property_id){
			let response = await api.get(this.$app, api.PROPERTIES +  data.property_id);
			commit('setNewLeaseProperty',  response.property);
			let responsehours = await api.get(this.$app, api.PROPERTIES +  data.property_id + '/hours');
			commit('setNewLeaseHours', responsehours.hours);
		}
	},

	async getPropertyHours({getters, commit}, data){
		if(!getters.newLeaseProperty.id || getters.newLeaseProperty.id !==  data.property_id){
			let responsehours = await api.get(this.$app, api.PROPERTIES +  data.property_id + '/hours');
			commit('setNewLeaseHours', responsehours.hours);
		}
	},

	async getContactInfo({getters, commit}, data){
		if(!getters.newLeaseContact.id || getters.newLeaseContact.id !== data.contact_id){
			let response = await api.get(this.$app, api.CONTACTS +  data.contact_id);
			commit('setNewLeaseContact',  response.contact);
			let lead_response = await api.get(this.$app, api.CONTACTS +  data.contact_id + '/lead');
      commit('setNewLeaseLead',  lead_response.lead);
		}
	},

  async getUnitInfo({getters, commit}, data){
    if(!getters.leaseUnit.id || getters.leaseUnit.id !==  data.unit_id){
      let response = await api.get(this.$app, api.UNITS +  data.unit_id);
      commit('setUnit',  response.unit);
    }
  },

	async getPromotions({getters, commit}, data){
		let r = await api.get(this.$app, api.UNITS + data + '/promotions');
		commit('setPromotions', r.promotions);
		if(getters.lease.promotion_id){
			let promotion = this.promotions.filter(p => p.id === this.$store.getters.lease.promotion_id);
			if(promotion.length){
				commit('updatePromotion', promotion[0]);
			}
		}
	},

	async findLeaseTenants({getters, commit}){
		let r = await api.get(this.$app, api.LEASES + getters.lease.id + '/tenants');
		commit('setTenants', r.tenants);
	},

	async findLeaseServices({getters, commit}){
		let r = await api.get(this.$app, api.LEASES + getters.lease.id + '/services');
		commit('setServices', r.services);
		// Because we can only have one insurance product on a lease currently
		if(r.insurance.length){
			commit('setInsurance', r.insurance[0]);
		}

	},

	async findLeaseChecklistItems({getters, commit}){
		let r = await api.get(this.$app, api.LEASES + getters.lease.id + '/checklist');
		commit('setChecklistItems', r.items);

	},

  async findPending({getters, commit, dispatch}, data) {

    let r = await api.get(this.$app, api.UNITS + data.unit_id + '/pending');
    if (r.lease) {
      r.lease.start_date = moment(r.lease.start_date, 'YYYY-MM-DD').startOf('day').valueOf();
      r.lease.end_date = r.lease.end_date ? moment(r.lease.end_date, 'YYYY-MM-DD').startOf('day').valueOf() : null;

      await commit('setLease', r.lease);
      await dispatch('getUnitInfo', {unit_id: data.unit_id});
      await dispatch('getPropertyInfo', {property_id: getters.leaseUnitPropertyId});
      await dispatch('getPropertyHours', {property_id: getters.leaseUnitPropertyId});
      await dispatch('findLeaseTenants');
      await dispatch('findLeaseServices');
      // await dispatch('findLeasePaymentMethods');
      await dispatch('findLeaseTemplates');
      await dispatch('getEstimate');

    }
  },

  async findReservation({getters, commit, dispatch}, data) {

    if (data.Lease) {
      data.Lease.start_date = moment(data.Lease.start_date, 'YYYY-MM-DD').startOf('day').valueOf();
      data.Lease.end_date = data.Lease.end_date ? moment(data.Lease.end_date, 'YYYY-MM-DD').startOf('day').valueOf() : null;

      await commit('setReservation', data);
      await commit('setLease', data.Lease);
      //await commit('setActiveContactId', data.contact_id);
      await dispatch('getUnitInfo', {unit_id: data.Lease.unit_id});
      await dispatch('getPropertyInfo', {property_id:  data.Lease.Unit.property_id});
      await dispatch('getPropertyHours', {property_id:  data.Lease.Unit.property_id});
      await dispatch('findLeaseTenants');

      if(getters.leaseTenants.length){
        let contact = getters.leaseTenants[0].Contact;
        dispatch('getContactInfo', {contact_id : contact.id});
      }

      await dispatch('findLeaseServices');
      //await dispatch('findLeasePaymentMethods');
      await dispatch('findLeaseTemplates');

    }
  },


  async findLeaseTemplates({getters, commit}, data) {
    let r = await api.get(this.$app, api.PROPERTIES + getters.leaseUnitPropertyId + '/templates')
    if(!r.templates[getters.leaseUnitType] || !r.templates[getters.leaseUnitType].Template) return;
    var template = r.templates[getters.leaseUnitType].Template;
    commit('setTemplate', template);


  },

  async findLeasePaymentMethods({getters, commit}, data) {
    let r = await api.get(this.$app, api.LEASES + getters.lease.id + '/payment-methods');
    commit('setPaymentMethods', r.paymentMethods);
  },






};

export default {
	state,
	mutations,
	actions,
	getters
}
